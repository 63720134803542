import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {fetch} from "../utils/api-wrapper";


const WorkflowStepDisplayCard = ({
                                     step,
                                     onClickFunc,
                                     isSelected,
                                     data,
                                     branch,
                                     removeStep,
                                     index,
                                 }) => {
    // Local hover state to conditionally show the remove button.
    const [isHover, setIsHover] = useState(false);
    const [approvers, setApprovers] = useState([]);

    // ------------------ HELPER FUNCTIONS ------------------

    // Fetch approvers on component mount.
    useEffect(() => {
        fetchApprovers();
    }, []);

    const fetchApprovers = () => {
        fetch("/client/users/Approver", "GET", {}, (response) => {
            setApprovers(response.data);
        });
    };

    const approverMap = (approver) => {
        return {
            value: approver?.id_user,
            label: `${approver?.first_name} ${approver?.last_name}`,
        };
    };

    // If no step is provided, do not render anything.
    if (!step) return null;

    // ------------------ HELPER FUNCTIONS ------------------

    /**
     * getConditionLabel
     * Returns a summary label for a condition.
     */
    const getConditionLabel = (condition) => {
        // Mapping of condition types to translated labels.
        const conditionMap = {
            amount_lower: data.t.read("rule.the_amount_is_lower"),
            amount_greater: data.t.read("rule.the_amount_is_greater"),
            supplier_number: data.t.read("rule.the_supplier_number_equals"),
            supplier_number_not_equal: data.t.read("rule.the_supplier_number_not_equals"),
            cost_type: data.t.read("rule.the_cost_type_equals"),
            cost_type_not_equal: data.t.read("rule.the_cost_type_not_equals"),
            client_number: data.t.read("rule.the_client_number_equals"),
            gl_unit_number: data.t.read("rule.the_gl_unit_number_equals"),
            client_number_not_equal: data.t.read("rule.the_client_number_not_equals"),
            invoice_billable: data.t.read("rule.invoice_is_set_as"),
            invoice_custom: data.t.read("rule.invoice_is_custom"),
            supplier_name_equals: data.t.read("rule.supplier_name_equals"),
            gl_unit_is: data.t.read("rule.gl_unit_is"),
            gl_unit_is_not: data.t.read("rule.gl_unit_is_not"),
            user_has_role: data.t.read("rule.user_has_role"),
            line_item_amount: data.t.read("rule.the_line_item_amount"),
            item_description: data.t.read("rule.item_description"),
            item_cost_type: data.t.read("rule.item_cost_type"),
            item_gl: data.t.read("rule.item_gl"),
            currency: data.t.read("rule.currency_is")
        };

        // Start with the translated label or the raw type.
        let label = conditionMap[condition.type] || condition.type;

        // For some condition types, include the operator.
        if (
            ["item_description", "line_item_amount", "item_cost_type", "item_gl"].includes(
                condition.type
            )
        ) {
            label += ` ${data.t.read(`rule.${condition.operator}`)}`;
        }

        // Append value or role details for various condition types.
        if (condition.type === "invoice_billable") {
            label += condition.value === "1"
                ? ` ${data.t.read("rule.billable")}`
                : ` ${data.t.read("rule.non_billable")}`;
        } else if (condition.type === "user_has_role" && condition.role) {
            label += `: ${data.t.read("role_" + condition.role.toLowerCase())}`;
        } else if (condition.type === "invoice_custom") {
            label += condition.value === "1"
                ? `: ${data.t.read("rule.invoice_custom")}`
                : `: ${data.t.read("rule.invoice_not_custom")}`;
        }
        else if (condition.type === "line_item_amount" || condition.type === "amount_lower" || condition.type === "amount_greater"){
            label += `: $${condition.value}`;
        }
        else if (
            ![
                "invoice_billable",
                "user_has_role",
                "invoice_custom",
                "line_item_amount",
            ].includes(condition.type) &&
            condition.value
        ) {
            label += `: ${condition.value}`;
        }
        return label;
    };

    /**
     * getActionLabel
     * Returns a summary label for an action.
     */
    const getActionLabel = (action) => {
        // Mapping of action types to translated labels.
        const actionMap = {
            send_email: data.t.read("rule.send_email_to"),
            split_items: data.t.read("rule.split_items"),
            only_keep_total: data.t.read("rule.only_keep_total"),
            change_currency: data.t.read("rule.change_currency_for"),
            change_all_totals: data.t.read("rule.change_all_totals"),
            change_user: data.t.read("rule.change_user"),
            change_item_description: data.t.read("rule.change_item_description"),
            change_item_detail: data.t.read("rule.change_item_detail"),
            change_status: data.t.read("rule.change_status"),
            achat_and_payment: `${data.t.read("activate")} ${data.t
                .read("achat_and_payment")
                .toLowerCase()}`,
            request_approval: data.t.read("rule.request_approval"),
        };

        let label = actionMap[action.type] || action.type;

        // For many action types, append the action's value.
        if (
            [
                "change_item_description",
                "change_user",
                "change_currency",
                "send_email",
                "change_status",
            ].includes(action.type) &&
            action.value
        ) {
            label += `: ${action.value}`;
        } else if (action.type === "request_approval" && action.value) {
            label += ` ${  approverMap(approvers.filter((a) => a.id_user === action.value)[0]).label}`;
        }
        else if (action.type === "change_item_detail"){
            label += `: ${action.field} ${data.t.read("to").toLocaleLowerCase()} ${action.value}`;
        }
        else if (action.type === "change_all_totals") {
            label += `: ${action.value}%`;
        }

        return label;
    };

    /**
     * validateCondition
     * Checks if a condition object is valid.
     */
    const validateCondition = (condition) => {
        // Must have a nonempty type.
        if (!condition.type || condition.type.trim() === "") return false;
        // For certain types, operator must be provided.
        if (
            ["item_description", "item_cost_type", "item_gl"].includes(condition.type) &&
            (!condition.operator || condition.operator === "")
        ) {
            return false;
        }
        // Value must be nonempty.
        if (
            condition.value === undefined ||
            condition.value === null ||
            condition.value.toString().trim() === ""
        )
            return false;
        return true;
    };

    /**
     * validateAction
     * Checks if an action object is valid.
     */
    const validateAction = (action) => {
        if (!action.type || action.type.trim() === "") return false;
        if (action.type !== "only_keep_total" && action.type != "achat_and_payment" &&
            (action.value === undefined || action.value === null || action.value.toString().trim() === "")) {
            return false;}
        return true;
    };

    /**
     * validateStep
     * Checks that the step has at least one valid condition and one valid action.
     */
    const validateStep = (step) => {
        if (!step.conditions || !Array.isArray(step.conditions) || step.conditions.length === 0)
            return false;
        if (!step.actions || !Array.isArray(step.actions) || step.actions.length === 0)
            return false;
        for (let condition of step.conditions) {
            if (!validateCondition(condition)) return false;
        }
        for (let action of step.actions) {
            if (!validateAction(action)) return false;
        }
        return true;
    };

    // -------------------- RENDERING --------------------
    return (
        <div
            className={`card border-radius-30 w-100 ${!validateStep(step) ? "border-highlight" : ""}`}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
        >
            <div className="d-flex">
                {/* Main step details area */}
                <div
                    className="card-body p-3 pr-0"
                    onClick={onClickFunc}
                    style={{ cursor: "pointer" }}
                >
                    {/* Render Conditions if any */}
                    {step.conditions.length > 0 && (
                        <div className="mb-2">
                            <h6 className="mb-1 text-muted">
                                {data.t.read("if_all_conditions_match")}:
                            </h6>
                            <ul className="mb-2 ps-3">
                                {step.conditions.map((c) => (
                                    <li
                                        key={c.id}
                                        className={` p-1 border-radius-30 ${!validateCondition(c) ? "border-highlight-0" : ""}`}
                                    >
                                        {getConditionLabel(c)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {/* Render Actions if any */}
                    {step.actions.length > 0 && (
                        <div>
                            <h6 className="mb-1 text-muted">{data.t.read("rule.then")}:</h6>
                            <ul className="mb-0 ps-3">
                                {step.actions.map((action, idx) => (
                                    <li
                                        key={idx}
                                        className={`p-1 border-radius-30 ${!validateAction(action) ? "border-highlight-0" : ""}`}
                                    >
                                        {getActionLabel(action)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                {/* Remove button, visible only on hover */}
                <div className="d-flex align-items-center p-2">
                    <button
                        className={`btn btn-sm btn-light border-radius-30 position-absolute ${isHover ? "" : "d-none"}`}
                        onClick={removeStep}
                        style={{ top: "-3px", right: "-3px" }}
                        title="Remove step"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WorkflowStepDisplayCard;