import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import Utils from "../utils/utils";
import Modal from '../components/modal';
import FormSelectUser from "../components/form-select-user";
import { fetch } from '../utils/api-wrapper';
import FormTextfield from "../components/form-textfield";
import TransactionList from "../components/transaction-list";
import Transaction from "../components/transaction";
const CreditCardStatementUpdate = ({ data }) => {
    const source = axios.CancelToken.source();
    const { statement_id } = useParams(); // Access the id parameter from the URL
    const [statement, setStatement] = useState();
    const [showRequestApproval, setShowRequestApproval] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [savingError, setSavingError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [action, setAction] = useState(null);
    const [approver, setApprover] = useState(null);
    const [approvers, setApprovers] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const timeoutId = useRef(null);
    const navigate = useNavigate(); // Hook to navigate programmatically

    const [previousStatus, setPreviousStatus] = useState(null);

    const fetchStatement = () => {
        const searchData = `?statement_id=${encodeURIComponent(statement_id)}`;

        fetch(`/c_card/statement/${searchData}`, "GET", {},
            (response) => {
                setStatement(response?.data?.statement);
                if (response?.data?.statement?.expenses?.find(x => ["Pending", "Processing"].includes(x.status)))
                    timeoutId.current = setTimeout(
                        () => fetchStatement(), 3000
                    );
                else
                    clearInterval(timeoutId.current);
            }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchStatement();
            }
            , false, source.token);
    }

    useEffect(() => {
        fetchStatement();
    }
        , [statement_id])

    useEffect(() => {
        setPreviousStatus(statement?.status)

        if (!statement?.status || previousStatus === null || previousStatus === undefined)
            return;

        if (previousStatus !== statement.status)
            setSuccessMessage(`${data.t.read('statement')} ${data.t.read(`statement_status.${statement.status.toLowerCase()}`)}`);

        setPreviousStatus(statement.status)
    }
        , [statement?.status])

    useEffect(() => {
        fetchApprover();
        return () => {
            source.cancel('statement_update unmounted');
            clearInterval(timeoutId.current);
        }
    }, [])

    const closeInvoicePrompt = () => {
        fetchStatement();
        setCurrentTransaction(null);
    }

    const submit = () => {
        setSaving(true)
        setAction('submit');
        fetch("/c_card/statement/submit/" + statement_id, "PUT", null,
            response => {
                setSaving(false);
                navigate(-1);
            },
            error => {
                setSaving(false);
                if (error.response?.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const updateStatement = (update) => {
        setSaving(true)
        setAction('update_statement');
        fetch("/c_card/statement/" + statement_id, "PUT", update,
            response => {
                if (response?.data?.statement)
                    setStatement(response?.data?.statement);
                setSaving(false);
            },
            error => {
                setSaving(false);
                if (error.response?.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const fetchApprover = () => {
        fetch("/client/users/Approver", "GET", {},
            response => {
                setApprovers(response.data);
                setApprover(response.data ? response.data[0] : null);
            })
    }

    const request_approval = () => {
        setSaving(true);
        setAction('request_approval');
        const payload = {
            approver: approver,
            status: 'Need_Approval',
            status_details: new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + data.t.read("request_approval") + " " + data.t.read("sent") + " " + data.t.read("to") + " " + approver["last_name"] + " " + approver["first_name"] + '\n' + statement?.status_details
        }
        fetch("/c_card/statement/approval/" + statement_id, "PUT", payload,
            response => {
                setSaving(false);
                navigate(`/c_card/statements/`);
            },
            error => {
                setSaving(false);
                setShowRequestApproval(false);
                if (error.response.status !== 500 && error.response?.data?.message)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            })
    }

    const disApproveTransaction = (transaction, causeDenial) => {
        transaction.status = 'Denied';
        transaction.status_details = new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + causeDenial + '\n' + transaction?.status_details;
        return updateTransaction(transaction);
    }

    const approveTransaction = (transaction) => {
        transaction.status = 'Approved';
        return updateTransaction(transaction);
    }

    const validateTransaction = (transaction) => {
        setAction('validating');
        const formData = new FormData();
        formData.append("transaction", JSON.stringify(transaction));
        // Return a Promise to ensure we have a resolved value
        return new Promise((resolve) => {
            fetch(`/c_card/statement/validate/${statement_id}/${transaction.checksum}`,
                "PUT",
                formData,
                response => {
                    resolve(true); // Resolve with true on success
                },
                error => {
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                    resolve(false); // Resolve with false on error
                }
            );
        });
    }

    const updateTransaction = (transaction, action = 'update_approval') => {
        setAction(action);
        return new Promise((resolve) => {
            fetch("/c_card/transaction/" + statement_id + "/" + transaction.number, "PUT", transaction,
                response => {
                    setAction(null);
                    resolve(true);
                },
                error => {
                    setAction(null);
                    resolve(false);
                    setSavingError(data.t.read("internal_saving_error"))
                })
        });
    }

    return <div className="card p-1 mt-5 rounded-lg">
        <div className="card-body">
            <div className="card-title d-flex justify-content-center">
                <h2>{data.t.read("statement")}</h2>
            </div>
            <div className="d-flex justify-content-end m-1">
                {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) && <button type="button" className="btn btn-secondary mr-1 btn-secondary-shadow" disabled={saving | ["Pending", "Finalized"].includes(statement?.status)} onClick={() => setShowRequestApproval(true)}>
                    <div className="action-buttons action-buttons-sm">
                        {data.t.read("request_approval")}
                        {saving && action === 'request_approval' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>}
                <button type="button" className="btn btn-primary btn-primary-shadow" disabled={saving | statement?.status !== "Pending" | statement?.transactions.length === 0 | statement?.transactions?.filter(x => !x.checksum).length > 0} onClick={() => submit()}>
                    <div className="action-buttons action-buttons-sm">
                        {data.t.read("submit")}
                        {saving && action === 'submit' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>

            </div>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="information-panel-content"
                    id="information-panel-header"
                >
                    <h3>Information</h3>

                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.period")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{statement && data.t.read(`month.${statement.month_name}`)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("statusTitle")} :</label>
                        </div>
                        <div className="col-6">
                            <span> {data.t.read(`expenses_account_status.${statement?.status.toLowerCase()}`)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.currency")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{statement?.currency}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label> {data.t.read("number_of_receipts")} :</label>
                        </div>
                        <div className="col-6">
                            <span>{statement?.transactions?.length}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Description :</label>
                        </div>
                        <div className="col-5">
                            <FormTextfield locked={saving} value={statement?.description} validateChange={val => updateStatement({ 'description': val })} />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="finances-panel-content"
                    id="finances-panel-header"
                >
                    <h3>{data.t.read("summary")}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.sub_total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {statement?.Total?.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}1 :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {statement?.Tax1?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("invoice.tax")}2 :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {statement?.Tax2?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense Taxes :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {statement?.Tax?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>{data.t.read("tips")} :</label>
                        </div>
                        <div>
                            <span className="ml-1" >$ {statement?.Tip?.toFixed(2)}</span>
                        </div>
                    </div>

                    <div className='d-flex'>
                        <div className="col-6 text-right">
                            <label>Xpense {data.t.read("invoice.total")} :</label>
                        </div>
                        <div>
                            <span className="ml-1">$ {statement?.BigTotalPayed?.toFixed(2)}</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <TransactionList statement_id={statement_id} statement={statement} fetchStatement={fetchStatement} saving={saving} approveTransaction={approveTransaction} disApproveTransaction={disApproveTransaction} updateTransaction={updateTransaction} currentTransaction={currentTransaction} setCurrentTransaction={setCurrentTransaction} validateTransaction={validateTransaction} /> 
        </div>
        {
            savingError &&
            <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                <div className="text-danger">
                    {savingError}
                </div>
            </Modal>

        }
        {
            successMessage &&
            <Modal title="Success" okText={"OK"} okAction={() => { setSuccessMessage(null); navigate(`/c_card/statements/`); }}>
                <p className='alert-success'>{successMessage}</p>
            </Modal>
        }
        {
            showRequestApproval &&
            <Modal overflow="visible" invalid={approver === null} title={data.t.read("request_approval")} cancelAction={() => setShowRequestApproval(false)} okAction={() => request_approval()} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                <FormSelectUser chosenOption={statement?.approver} options={approvers.sort((a, b) => a["first_name"] + a["last_name"] > b["first_name"] + b["last_name"] ? 1 : -1)} optionChanged={option => { setApprover(option) }} />
            </Modal>
        }
        {currentTransaction && <div className="overlay" onClick={() => closeInvoicePrompt(false)}>
            <div className="overlay-content">
                <div className="invoice-container" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                     <Transaction statement_id={statement_id} selected={currentTransaction} data={data} close={() => { setCurrentTransaction(null); fetchStatement(); }} /> 
                </div>
            </div>
        </div>
        }
    </div>
}
export default inject('data')(observer(CreditCardStatementUpdate));