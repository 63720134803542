import { useState, useEffect } from 'react';
import { fetch } from '../utils/api-wrapper';
import axios from 'axios';

const LogHistory = ({ data, statement_id, checksum }) => {
    const source = axios.CancelToken.source();
    const [selectedLog, setSelectedLog] = useState(null);
    const [historyLogs, setHistoryLogs] = useState([]);

    useEffect(() => {
        fetchHistoryLogs();
        return () => {
            source.cancel();
        };
    }, []);


    const fetchHistoryLogs = () => {
        const searchData = `?statement_id=${encodeURIComponent(statement_id)}&checksum=${encodeURIComponent(checksum)}`;

        fetch(`/c_card/statement/logs/${searchData}`, "GET", {},
            (response) => {
                setHistoryLogs(response.data.logs);
            }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchHistoryLogs();
            }
            , false, source.token);
    };

    return <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>{data.t.read("by")}</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                    <tbody>
                {
                    historyLogs.map(log => {
                        return <tr key={log["_id"]} className={selectedLog === log ? "selected-log" : "row-hover"} onClick={() => setSelectedLog(log)}>
                            <td>{Intl.DateTimeFormat(`${data.t.lang}-US`, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, }).format(new Date(log["created_at"]))}</td>
                            <td>{log["event_type"]}</td>
                            <td>{log["user"] ? log["user"]["first_name"] + " " + log["user"]["last_name"] : log["username"]}</td>
                            <td>{log["notes"]}</td>
                            </tr>
                            })
                }
                    </tbody>
            </table>
            {
                selectedLog != null &&
                    <div className="card m-1 font-xsmall log-details">
                        <div className="card-body d-flex">
                            <div className="flex-grow-1 flex-1 p-2 flex-shrink-0 border-right">
                                <h5>{data.t.read("before")}</h5>
                                {selectedLog["diff_backward"]}
                            </div>
                            <div className="flex-grow-1 flex-1 p-2 flex-shrink-0">
                                <h5>{data.t.read("after")}</h5>
                                {selectedLog["diff_forward"]}
                            </div>
                    </div>
                </div>
            }
        </div>
};

export default LogHistory;