import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { inject, observer, Provider } from "mobx-react";
import 'bootstrap';

import Utils from './utils/utils';
import SignIn from './pages/signin';
import Settings from './pages/settings';
import Dashboard from './pages/dashboard';
import NewInvoice from './pages/new-invoice';
import Monitor from './pages/monitor';
import Cashflow from './pages/cashflow';
import Data from './pages/data';
import Workflow from './pages/workflow';
import AdminDashboard from './pages/admin/admin-dashboard';
import AdminClients from "./pages/admin/admin-clients";
import AdminSuppliers from "./pages/admin/admin-suppliers";
import AdminGlNaturals from "./pages/admin/admin-gl-naturals";
import AdminUsers from "./pages/admin/admin-users";
import AdminOffices from "./pages/admin/admin-offices";
import Archives from './pages/archives';
import Footer from './components/footer';
import Menu from './components/menu';
import Reports from './pages/reports';
import Paiements from './pages/paiements';
import PaiementsApproval from './pages/paiements-approval';
import PlProcessingAccount from './pages/pl-processing-account';
import ExpensesAccount from './pages/expenses_account';
import CreditCardStatements from './pages/credit-card-statements';
import CreditCardStatementUpdate from './pages/credit-card-statement-update';
import ExpensesAccountCreation from './pages/expenses_account_creation';
import NewExpenses from './pages/new-expenses';
import ExpensesImputations from "./pages/expenses_imputations";
import ExpensesRates from "./pages/expenses_rates";
import AdminNotifications from "./pages/admin/admin-notifications";
import Webhooks from "./pages/webhooks";

import { fetch } from './utils/api-wrapper';
import ProtectedRoute from './utils/protected-route';
import NewCreditCardTransaction from './pages/new-credit-card-transaction';

const App = inject("data")(observer(({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if(location.pathname === '/webhooks'){
            return;
        }

        if (window.localStorage.getItem("jwt") === null) {
            data.username = null;
            navigate('/signin', { replace: true });
            return;
        }
        if (data.expiration_date) {
            const expiration_date = new Date(data.expiration_date);
            const now = Date.now();
            const duree = (expiration_date - now) / (1000 * 60)
            if (duree > 30)
                return;
        }

        fetch("/refresh-token", "GET", {},
            response => {
                data.username = response.data.username;
                data.firstName = response.data.first_name;
                data.lastName = response.data.last_name;
                data.origin_role = response.data.origin_role;
                data.unit = response.data.unit;
                data.role = response.data.role;
                data.type = response.data.type;
                data.password_expired = response.data.password_expired;
                data.config = response.data.config;
                data.client_name = response.data.client_name;
                data.id_client = response.data.id_client;
                data.expiration_date = response.data.expiration_date;
                window.localStorage.setItem("jwt", response.data.token);

                if (data.password_expired) {
                    navigate('/settings');
                }

                if (location.pathname === "/signin" || location.pathname === "/") {
                    navigate('/dashboard');
                }
            },
            response => {
                window.localStorage.removeItem("jwt");
                navigate('/signin', { replace: true });
            }
        );
    }, [navigate, location.pathname, data]);

    return (
        <div className="App">
            <Provider data={data}>
                <Main />
            </Provider>
        </div>
    );
}));

const Main = inject("data")(observer(({ data }) => {
    return (
        <div className="full-height">
            {
                data.username !== null && data.username !== "" && !data.hideMenu &&
                <Menu />
            }
            {(!data.isMobile || data.isMobile && !data.isMenuOpen)
                && <div className={data.username !== null && data.username !== "" && !data.hideMenu && !data.isMobile ? "offset-menu p-4" : ""}>
                    <Routes>
                        <Route exact path="/" element={<SignIn />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/webhooks" element={<Webhooks />} />

                        <Route element={<ProtectedRoute/>}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/archives" element={<Archives />} />
                            <Route path="/payments/invoices" element={<Paiements />} />
                            <Route path="/payments/approval" element={<PaiementsApproval />} />
                            <Route path="/payments/processing_account" element={<PlProcessingAccount />} />
                            <Route path="/expenses_accounts" element={<ExpensesAccount />} />
                            <Route path="/expenses_account/:account_id" element={<ExpensesAccountCreation />} />
                            <Route path="/expenses_account/:account_id/add" element={<NewExpenses />} />
                            <Route path="/expenses_configuration/imputations" element={<ExpensesImputations />} />
                            <Route path="/expenses_configuration/rates" element={<ExpensesRates />} />
                            <Route path="/c_card/statements/:card_id" element={<CreditCardStatements />} />
                            <Route path="/c_card/statement/:statement_id" element={<CreditCardStatementUpdate />} />
                            <Route path="/c_card/statement/:statement_id/add" element={<NewCreditCardTransaction />} />

                            {Utils.userHasPageAccess(data, "monitor") && <Route path="/monitor" element={<Monitor />} />}
                            {Utils.userHasPageAccess(data, "cashflow") && <Route path="/cashflow" element={<Cashflow />} />}
                            {Utils.userHasPageAccess(data, "data") && <Route path="/data" element={<Data />} />}
                            {Utils.userHasPageAccess(data, "workflow") && <Route path="/workflow" element={<Workflow />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin" element={<AdminDashboard />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/clients" element={<AdminClients />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/suppliers" element={<AdminSuppliers />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/gl_naturals" element={<AdminGlNaturals />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/users" element={<AdminUsers />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/offices" element={<AdminOffices />} />}
                            {Utils.userHasPageAccess(data, "admin") && <Route path="/admin/notifications" element={<AdminNotifications/>}/>}

                            <Route path="/settings" element={<Settings />} />
                            <Route path="/new" element={<NewInvoice />} />
                        </Route>

                    </Routes>
                </div>
            }
            <Footer />
        </div>
    );
}));

export default App;
