import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEllipsisH, faSpinner, faPencilAlt, faCheck , faSync} from "@fortawesome/free-solid-svg-icons";

import Utils from "../utils/utils";
import Modal from '../components/modal';
import Checkbox from "../components/checkbox";
import { fetch } from '../utils/api-wrapper';

const ExpensesAccount = ({ data }) => {
    const history = useNavigate();
    const source = axios.CancelToken.source();
    const [expensesAccounts, setExpensesAccounts] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);
    const [globalfilter, setGlobalfilter] = useState("");
    const [xpensesDeleteSelection, setXpensesDeleteSelection] = useState([]);
    const [xpensesDeleteConfirm, setXpensesDeleteConfirm] = useState(false);
    const [savingError, setSavingError] = useState(null);
    

    const toggleAllXpensesSelected = () => {
        xpensesDeleteSelection.length === expensesAccounts.length ? setXpensesDeleteSelection([]) : setXpensesDeleteSelection([...expensesAccounts.flatMap(x => x._id.$oid)]);
    }
    const isXpenseSelected = (xpens) => {
        return xpensesDeleteSelection.find(x => x == xpens._id.$oid) != undefined;
    }

    const allColums = [
        { "name": "id", "evaluate": inv => inv._id ? inv._id.$oid : "", "displayName": "identifiant" },
        { "name": "created_at", "evaluate": inv => Intl.DateTimeFormat(`${data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(inv.created_at.$date)), "displayName": "created_at" },
        { "name": "updated_at", "evaluate": inv => Intl.DateTimeFormat(`${data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(inv.updated_at.$date)), "displayName": "updated_at" },
        { "name": "submitted_at", "evaluate": inv => inv.submitted_at ? Intl.DateTimeFormat(`${data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(inv.submitted_at.$date)) : "", "displayName": "submitted_at" },
        { "name": "currency", "evaluate": inv => inv.currency ? inv.currency : "", "displayName": "currency" },
        { "name": "status", "evaluate": inv => data.t.read(`expense_account_status.${inv.status.toLowerCase()}`), "displayName": "status" },
        { "name": "total", "evaluate": inv => inv.Total ? `$${inv.Total.toFixed(2)}` : "", "displayName": "total" },
        { "name": "totalWithTax", "evaluate": inv => inv.TotalWithTax ? `$${inv.TotalWithTax.toFixed(2)}` : "", "displayName": "total_with_tax" },
        { "name": "approver", "evaluate": inv => inv.approver ? `${inv.approver.first_name} ${inv.approver.last_name}` : "", "displayName": "approver" },
    ];
    const [availableColumns, setAvailableColumns] = useState([]);

    useEffect(() => {

        if (data.isMobile)
            setAvailableColumns(availableColumns.filter(col => ["created_at", "total"].includes(col.name)));
        else
            setAvailableColumns(allColums);

    }, [data.isMobile])

    const isAllXpensesSelected = () => {
        if (!expensesAccounts || !expensesAccounts.length)
            return false;
        return xpensesDeleteSelection.length > 1 && xpensesDeleteSelection.length === expensesAccounts.length;
    }

    const deleteXpenses = () => {
        setSaving(true);
        fetch(`/expenses_account/`, "DELETE", { "account_ids": xpensesDeleteSelection },
            response => {
                fetchExpensesAccounts();
                setXpensesDeleteSelection([]);
                setXpensesDeleteConfirm(false);
                setSaving(false);
            }
            , () => { setSaving(false); setXpensesDeleteConfirm(false); }, false, source.token);
    }

    const fetchExpensesAccounts = () => {
        let start = page * pageSize;

        if (globalfilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        fetch(`/expenses_accounts/${searchData}`, "GET", {}, (response) => {
            setExpensesAccounts(response.data.expenses_accounts);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchExpensesAccounts();
            }
            , false, source.token);
    }

    useEffect(() => {
        let cols = allColums.filter(col => col.name !== "approver" || Utils.userHasPageAccess(data, "approval"))

        if (data.isMobile)
            cols = cols.filter(col => ["created_at", "total"].includes(col.name));

        setAvailableColumns(cols)
        fetchExpensesAccounts();
    }
        , [])

    useEffect(() => {
        return () => {
            source.cancel('expenses_accounts list unmounted');
        }
    }, [])

    const createXpensesAccount = () => {
        setLoading(true);
        fetch(  `/expenses_account/`, "POST", '{}',
            response => {
                if (response.data.expenses_account_id)
                    openExpensesAccount(response.data.expenses_account_id);

                setLoading(false);
            },
            error => {
                setLoading(false);
                if (error.response.status !== 500)
                    setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);
                else
                    setSavingError(data.t.read("internal_saving_error"));
            }
            ,
            null, false, source.token)
    }

    const openExpensesAccount = (id) => {
        history(`/expenses_account/${id}`);
    }

    return !loading ? <div className="card p-1 mt-5 rounded-lg">
        <div className="card-body">
            <div className="card-title d-flex justify-content-center">
                <h2>{data.t.read("expenses_account_list")}</h2>
            </div>
            <div className="d-flex justify-content-end m-1">
                <button type="button" className="mr-1 text-center pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving || xpensesDeleteSelection.length === 0} onClick={() => setXpensesDeleteConfirm(true)}>
                    {data.t.read("delete")}
                    {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                </button>
                <button onClick={() => createXpensesAccount()} type="button" className="btn btn-primary btn-primary-shadow">
                    {data.t.read("add_new")}
                </button>
            </div>
            <div className="table m-0 p-0">
                <table className="table table-hover m-0 p-0 table-borderless ">
                    <thead>
                        <tr>
                            <th><Checkbox disabled={!expensesAccounts || !expensesAccounts.length} isChecked={isAllXpensesSelected()} onChange={toggleAllXpensesSelected} /></th>
                            <th><div></div></th>
                            {availableColumns.map((availableColumn) => (
                                <th key={`header-${availableColumn.displayName}}`}>{data.t.read(`invoice.${availableColumn.displayName}`)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {expensesAccounts.map(inv => <tr key={`${inv._id.$oid}-list`}>
                            <td><Checkbox isChecked={isXpenseSelected(inv)} onChange={e => isXpenseSelected(inv) ? setXpensesDeleteSelection(xpensesDeleteSelection.filter(x => x != inv._id.$oid)) : setXpensesDeleteSelection([...xpensesDeleteSelection, inv._id.$oid])} /></td>
                            <td onClick={() => openExpensesAccount(inv._id.$oid)}>
                                {inv.status === "Pending" && <FontAwesomeIcon icon={faPencilAlt} />}
                                {["Submitted", "Need_Approval"].includes(inv.status) && <FontAwesomeIcon icon={faEllipsisH} style={{ color: 'blue' }} />}
                                {inv.status === "Validated" && <FontAwesomeIcon icon={faCheck} className='text-primary' />}
                                {inv.status === "Approved" && <FontAwesomeIcon icon={faCheckCircle} className='text-primary' />}
                                {inv.status === "Denied" && <FontAwesomeIcon icon={faCheckCircle} className='text-danger' />}
                            </td>
                            {
                                availableColumns.map(availableColumn => <td onClick={() => openExpensesAccount(inv._id.$oid)} key={`${inv._id.$oid}-${availableColumn.displayName}-value`}>{availableColumn.evaluate(inv)}</td>)}
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        {
            xpensesDeleteConfirm &&
            <Modal title={data.t.read("confirm")} cancelAction={() => setXpensesDeleteConfirm(false)} okAction={() => deleteXpenses()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                {data.t.read("delete")} ?
            </Modal>
        }
        {
            savingError != null &&
            <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                <div className="text-danger">
                    {savingError}
                </div>
            </Modal>
        }
    </div>
        :
        <div className="d-flex loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
}

export default inject('data')(observer(ExpensesAccount));