import { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { IoMenu } from "react-icons/io5";

import Translator from '../locales/translator';
import logo from '../assets/img/logo-xpens.svg';
import Utils from '../utils/utils';
import { fetch } from '../utils/api-wrapper';
const Menu = inject("data")(observer(({ data }) => {
    const location = useLocation();
    const history = useNavigate();
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    // TODO: get cards from backend
    const [cards, setCards] = useState([
        { id: 343325, name: "•••• •••• •••• 9018", type: "p-card" },
        { id: 234242, name: "•••• •••• •••• 4523", type: "personal-card" },
    ]);

    const [isMenuOpen, setIsMenuOpen] = useState(!data.isMobile);

    useEffect(() => {
        data.isMenuOpen = isMenuOpen;
    }, [isMenuOpen]);

    const toggleNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMobileMenu = () => {
        if (data.isMobile) {
            setIsMenuOpen(false);
        }
    };

    const updateLang = () => {
        data.t.switchLang();
        data.t = new Translator();
    };

    const signout = () => {
        data.username = "";
        data.config = null;
        data.isMenuOpen = false;
        window.localStorage.removeItem("jwt");
        history('/signin', {replace: true});
    };

    const fetchUSers = () => {
        const filters = [{id: "id_client", value: data.id_client}];

        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}`;
        fetch(  `/users/${searchData}`, "GET", {}, response => {
            let fetchedUsers = response.data.users;
            setCards([...fetchedUsers.filter(user => user.card_number).map(user => ({id: user._id.$oid, name: Utils.decryptCardNumber(user.card_number), type: user.card_type}))]);
        });
    }

    useEffect(() => {
        if(!data.id_client)
            return;
        fetchUSers();
    }, [data.id_client]);

    return (
        <div className={data.isMobile ? "mobile_menu" : "menu"} >
            <div className={data.isMobile ? "mobile_menu_header" : "mt-4 mb-5"} >
                <div>
                    <img style={{ maxWidth: '200px', maxHeight: '60px' }} src={data.config && data.config.logo ? data.config.logo : logo} alt="XPENS logo" />
                </div>
                {data.isMobile && <div onClick={toggleNav}>
                    <IoMenu />
                </div>}
            </div>
            {isMenuOpen && <nav>
                <ul className="iconed">
                    {!data.isMobile && <> <li onClick={closeMobileMenu} className={`dashboard-link ${location.pathname === "/dashboard" ? "selected" : ""}`}>
                        <Link to="/dashboard">
                            {data.t.read("dashboard")}
                        </Link>
                    </li>
                        <li onClick={closeMobileMenu} className={`dashboard-link ${location.pathname === "/archives" ? "selected" : ""}`}>
                            <Link to="/archives">
                                {data.t.read("archives")}
                            </Link>
                        </li>
                        <li onClick={closeMobileMenu} className={`dashboard-link ${location.pathname === "/reports" ? "selected" : ""}`}>
                            <Link to="/reports">
                                {data.t.read("reports")}
                            </Link>
                        </li></>}
                    {!data.isMobile && Utils.userHasPageAccess(data, "payments") && (
                        <div className="dropdown">
                            <li onClick={closeMobileMenu} className={`dropdown-toggle payments-link ${location.pathname.indexOf("/payments") !== -1 ? "selected" : ""}`} type="button" data-toggle="dropdown">
                                {data.t.read("payments")}
                            </li>
                            <div className="dropdown-menu">
                                <Link onClick={closeMobileMenu} className="dropdown-item" to="/payments/invoices">
                                    {data.t.read("invoices")}
                                </Link>
                                <Link onClick={closeMobileMenu} className="dropdown-item" to="/payments/approval">
                                    {data.t.read("approval")}
                                </Link>
                                <Link onClick={closeMobileMenu} className="dropdown-item" to="/payments/processing_account">
                                    {data.t.read("processing_account")}
                                </Link>
                            </div>
                        </div>
                    )}
                    {Utils.userHasPageAccess(data, "expenses") && (
                        <div className="dropdown">
                            <li className={`dropdown-toggle data-link ${location.pathname === "/expenses_account" ? "selected" : ""}`} type="button" data-toggle="dropdown">
                                {data.t.read("expenses_account")}
                            </li>
                            <div className="dropdown-menu">
                                <Link onClick={closeMobileMenu} className="dropdown-item" to="/expenses_accounts">
                                    {data.t.read("expenses_account")}
                                </Link>
                                <Link onClick={closeMobileMenu} className="dropdown-item" to="/expenses_configuration/imputations">
                                    {data.t.read("configuration")}
                                </Link>
                            </div>
                        </div>
                    ) }
                    {Utils.userHasPageAccess(data, "c-card") && (
                        <div className="dropdown">
                            <li
                                className={`dropdown-toggle data-link ${location.pathname.startsWith("/c_card") ? "selected" : ""}`}
                                type="button"
                                data-toggle="dropdown"
                            >
                                {data.t.read("c-card")}
                            </li>
                            <div className="dropdown-menu pl-1">
                                <div className="dropdown-submenu">
                                    {!subMenuOpen && <li className="dropdown-item data-link pointer" onClick={(e) => {setSubMenuOpen(!subMenuOpen) ; e.stopPropagation()}}>{data.t.read("statements")}</li>}
                                    {subMenuOpen && <div className="submenu">
                                        {cards.map((card) => (
                                            <li key={card.id} className="payments-link">
                                                <Link onClick={closeMobileMenu} className="dropdown-item " to={`/c_card/statements/${card.id}?type=${card.type}`}>
                                                    {Utils.getMaskedDisplayValue(card.name)}
                                                </Link>
                                            </li>
                                        ))
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                    )}

                    {!data.isMobile && Utils.userHasPageAccess(data, "monitor") && (
                        <li onClick={closeMobileMenu} className={`data-link ${location.pathname === "/monitor" ? "selected" : ""}`}>
                            <Link to="/monitor">
                                {data.t.read("monitor")}
                            </Link>
                        </li>
                    )}
                    {!data.isMobile && Utils.userHasPageAccess(data, "cashflow") && (
                        <li onClick={closeMobileMenu} className={`data-link ${location.pathname === "/cashflow" ? "selected" : ""}`}>
                            <Link to="/cashflow">
                                {data.t.read("cashflow")}
                            </Link>
                        </li>
                    )}
                    {!data.isMobile && Utils.userHasPageAccess(data, "data") && (
                        <li onClick={closeMobileMenu} className={`data-link ${location.pathname === "/data" ? "selected" : ""}`}>
                            <Link to="/data">
                                {data.t.read("data")}
                            </Link>
                        </li>
                    )}
                    {!data.isMobile && Utils.userHasPageAccess(data, "workflow") && (
                        <li onClick={closeMobileMenu} className={`workflow-link ${location.pathname === "/workflow" ? "selected" : ""}`}>
                            <Link to="/workflow">
                                {data.t.read("workflow")}
                            </Link>
                        </li>
                    )}
                    {!data.isMobile && <li onClick={closeMobileMenu} className={`settings-link ${location.pathname === "/settings" ? "selected" : ""}`}>
                        <Link to="/settings">
                            {data.t.read("settings")}
                        </Link>
                    </li>}
                </ul>
            </nav>}
            {
                data.role !== 'Approver' && !data.isMobile && (location.pathname === "/dashboard" || location.pathname === "/archives") && (
                    <div>
                        <h5 className="mt-4 mb-4">
                            {data.t.read("show")}
                        </h5>
                        <nav>
                            <ul>
                                <li className={`pointer ${data.selectedStatus === "All" ? "selected" : ""}`} onClick={() => data.selectedStatus = "All"}>
                                    <span>{data.t.read("status.all")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Pending" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Pending"}>
                                    <span>{data.t.read("status.pending")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Processing" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Processing"}>
                                    <span>{data.t.read("status.processing")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Need_Attention" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Need_Attention"}>
                                    <span>{data.t.read("status.need_attention")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Completed" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Completed"}>
                                    <span>{data.t.read("status.completed")}</span>
                                </li>
                                {data.config?.invoice_status.indexOf("Need_Approval") !== -1 && (
                                    <li className={`pointer ${data.selectedStatus === "Need_Approval" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Need_Approval"}>
                                        <span>{data.t.read("status.need_approval")}</span>
                                    </li>
                                )}
                                {data.config?.invoice_status.indexOf("Denied") !== -1 && (
                                    <li className={`pointer ${data.selectedStatus === "Denied" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Denied"}>
                                        <span>{data.t.read("status.denied")}</span>
                                    </li>
                                )}
                                {data.config?.invoice_status.indexOf("Approved") !== -1 && (
                                    <li className={`pointer ${data.selectedStatus === "Approved" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Approved"}>
                                        <span>{data.t.read("status.approved")}</span>
                                    </li>
                                )}
                                <li className={`pointer ${data.selectedStatus === "Validated" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Validated"}>
                                    <span>{data.t.read("status.validated")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Finalized" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Finalized"}>
                                    <span>{data.t.read("status.finalized")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Invalid" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Invalid"}>
                                    <span>{data.t.read("status.invalid")}</span>
                                </li>
                                <li className={`pointer ${data.selectedStatus === "Deleted" ? "selected" : ""}`} onClick={() => data.selectedStatus = "Deleted"}>
                                    <span>{data.t.read("status.deleted")}</span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}

            {isMenuOpen && <div className="mt-auto mb-4">
                <ul>
                    {!data.isMobile && Utils.userHasPageAccess(data, "admin") && Utils.userHasRole(data, ["Admin", "SuperAdmin", "Manager", "Clerk"]) && (
                        <li onClick={closeMobileMenu} className={`admin-link ${location.pathname === "/admin" ? "selected" : ""}`}>
                            <Link to="/admin/suppliers">
                                Administration
                            </Link>
                        </li>
                    )}
                    <li className="pointer" onClick={() => { updateLang(); closeMobileMenu(); }}>
                        {data.t.lang === "fr" ? (
                            <span><strong>Français</strong> / English</span>
                        ) : (
                            <span>Français / <strong>English</strong></span>
                        )}
                    </li>
                    <li className="pointer" onClick={() => signout()}>
                        {data.t.read("signout")}
                    </li>
                </ul>
            </div>}
        </div>
    );
}));

export default Menu;
