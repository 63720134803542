import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import MoneyInput from "./money-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Utils from "../utils/utils";
import {fetch} from "../utils/api-wrapper";


const WorkflowEditStepModal = ({ selectedStep, setSelectedStep, data, onSubmit, onCancel }) => {
    // Local copy of the step being edited. Changes are applied locally until submission.
    const [localStep, setLocalStep] = useState({ ...selectedStep });
    // Reference for generating unique IDs for split item lines.
    const valId = useRef(0);

    // Whenever localStep changes, update the parent selectedStep.
    useEffect(() => {
        setSelectedStep({ ...localStep });
    }, [localStep, setSelectedStep]);

    const updateCondition = (property, condition, event) => {
        if (!localStep) return;
        const updatedStep = {
            ...localStep,
            conditions: localStep.conditions.map(c =>
                c.id === condition.id ? {
                ...c, [property]: event.target.value, ...(property === "type" ? { value: "", operator: "equal" } : {})} : c,
            ),
        };
        setLocalStep(updatedStep);
    };


    const addCondition = () => {
        if (!selectedStep) return;
        const updatedStep = { ...localStep };
        const newConditionId = updatedStep.conditions.length + 1;
        const newCondition = {
            id: newConditionId,
            type: "amount_greater",
            operator: "equal",
            value: "",
        };
        updatedStep.conditions = [...updatedStep.conditions, newCondition];
        setLocalStep(updatedStep);
    };


    const removeCondition = (cond) => {
        const updatedStep = { ...localStep };
        updatedStep.conditions = updatedStep.conditions.filter(c => c.id !== cond.id);
        // Reassign condition IDs sequentially.
        updatedStep.conditions = updatedStep.conditions.map((c, idx) => ({ ...c, id: idx + 1 }));
        setLocalStep(updatedStep);
    };

    // -------------------- ACTION HANDLERS --------------------

    // Approvers state is used for actions of type "request_approval"
    const [approvers, setApprovers] = useState([]);


    const updateAction = (property, actionObj, event) => {
        if (!selectedStep) return;
        const updatedStep = { ...localStep };
        if (property === "addAction") {
            // Append a new action with default values.
            const newAction = {
                id: updatedStep.actions.length + 1,
                type: "send_email",
                value: "",
            };
            updatedStep.actions.push(newAction);
        } else {
            // Update the matching action.
            updatedStep.actions = updatedStep.actions.map(a => {
                if (a.id === actionObj.id) {
                    // If updating the type, reset the value.
                    if (property === "type") {
                        return { ...a, [property]: event.target.value, value: "" };
                    }
                    // For other properties, simply update.
                    return { ...a, [property]: event.target.value };
                }
                return a;
            });
        }
        setLocalStep(updatedStep);
    };


    const removeAction = (actionObj) => {
        if (!selectedStep) return;
        const updatedStep = { ...localStep };
        updatedStep.actions = updatedStep.actions.filter(a => a.id !== actionObj.id);
        setLocalStep(updatedStep);
    };


    const addSplitItemValue = (actionObj) => {
        if (!selectedStep) return;
        const updatedStep = { ...localStep };
        updatedStep.actions = updatedStep.actions.map(a => {
            if (a.id === actionObj.id) {
                if (!Array.isArray(a.value)) {
                    a.value = [];
                }
                a.value.push({
                    id: ++valId.current,
                    percent: 100,
                    updateInfo: "",
                    updateInfoValue: "",
                    updateCharacterAmount: null,
                    replaceAtTheStart: false,
                    maxAmount: "",
                });
            }
            return a;
        });
        setLocalStep(updatedStep);
    };


    const removeSplitItemValue = (actionObj, line) => {
        const updatedStep = { ...localStep };
        updatedStep.actions = updatedStep.actions.map(a => {
            if (a.id === actionObj.id) {
                a.value = (a.value || []).filter(v => v !== line);
            }
            return a;
        });
        setLocalStep(updatedStep);
    };


    const updateSplitItemValue = (actionObj, line, property, e) => {
        if (!selectedStep) return;
        // Helper function: Convert string to boolean if applicable.
        const str2bool = (value) => {
            if (typeof value === "string") {
                if (value.toLowerCase() === "true") return true;
                if (value.toLowerCase() === "false") return false;
            }
            return value;
        };
        const updatedStep = { ...localStep };
        updatedStep.actions = updatedStep.actions.map(a => {
            if (a.id === actionObj.id) {
                a.value = (a.value || []).map(v => {
                    if (v === line) {
                        return { ...v, [property]: str2bool(e.target.value) };
                    }
                    return v;
                });
            }
            return a;
        });
        setLocalStep(updatedStep);
    };

    // -------------------- OPTIONS FOR SELECT --------------------

    // Define the list of action types.
    const action_types = [
        { value: "send_email", label: data.t.read("rule.send_email_to") },
        { value: "split_items", label: data.t.read("rule.split_items") },
        { value: "only_keep_total", label: data.t.read("rule.only_keep_total") },
        { value: "change_currency", label: data.t.read("rule.change_currency_for") },
        { value: "change_all_totals", label: data.t.read("rule.change_all_totals") },
        { value: "change_user", label: data.t.read("rule.change_user") },
        { value: "change_item_description", label: data.t.read("rule.change_item_description") },
        { value: "change_item_detail", label: data.t.read("rule.change_item_detail") },
        { value: "change_status", label: data.t.read("rule.change_status") },
        {
            value: "achat_and_payment",
            label: `${data.t.read("activate")} ${data.t.read("achat_and_payment").toLowerCase()}`,
        },
        ...(Utils.userHasPageAccess(data, "approval")
            ? [{ value: "request_approval", label: data.t.read("rule.request_approval") }]
            : []),
    ];

    // Define the list of condition types.
    const condition_types = [
        { value: "amount_lower", label: data.t.read("rule.the_amount_is_lower") },
        { value: "amount_greater", label: data.t.read("rule.the_amount_is_greater") },
        { value: "supplier_number", label: data.t.read("rule.the_supplier_number_equals") },
        {
            value: "supplier_number_not_equal",
            label: data.t.read("rule.the_supplier_number_not_equals"),
        },
        { value: "cost_type", label: data.t.read("rule.the_cost_type_equals") },
        { value: "cost_type_not_equal", label: data.t.read("rule.the_cost_type_not_equals") },
        { value: "client_number", label: data.t.read("rule.the_client_number_equals") },
        { value: "gl_unit_number", label: data.t.read("rule.the_gl_unit_number_equals") },
        { value: "client_number_not_equal", label: data.t.read("rule.the_client_number_not_equals") },
        { value: "invoice_billable", label: data.t.read("rule.invoice_is_set_as") },
        { value: "invoice_custom", label: data.t.read("rule.invoice_is_custom") },
        { value: "supplier_name_equals", label: data.t.read("rule.supplier_name_equals") },
        { value: "gl_unit_is", label: data.t.read("rule.gl_unit_is") },
        { value: "gl_unit_is_not", label: data.t.read("rule.gl_unit_is_not") },
        { value: "user_has_role", label: data.t.read("rule.user_has_role") },
        { value: "line_item_amount", label: data.t.read("rule.the_line_item_amount") },
        { value: "item_description", label: data.t.read("rule.item_description") },
        { value: "item_cost_type", label: data.t.read("rule.item_cost_type") },
        { value: "item_gl", label: data.t.read("rule.item_gl") },
        { value: "currency", label: data.t.read("rule.currency_is") },
    ];

    // Fetch approvers on component mount.
    useEffect(() => {
        fetchApprovers();
    }, []);

    const fetchApprovers = () => {
        fetch("/client/users/Approver", "GET", {}, (response) => {
            setApprovers(response.data);
        });
    };

    // Helper mapping function for displaying an approver in a Select.
    const approverMap = (approver) => {
        return {
            value: approver?.id_user,
            label: `${approver.first_name} ${approver.last_name}`,
        };
    };

    // Common styles for react-select components.
    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const actionSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    // If no step is selected, render nothing.
    if (!selectedStep) return null;


    return (
        <div className={"workflow-container "} style={{background: "white"}}>
            <form onSubmit={onSubmit}>
                <div>
                    <h3 className="mx-4 d-flex align-items-center">{data.t.read("name")}: <input
                        type="text"
                        onFocus={(e) => e.target.select()}
                        required
                        className="ml-4 form-control"
                        placeholder={data.t.read("rule.name")}
                        value={localStep.name ?? ""}
                        onChange={(e) =>
                            setLocalStep({
                                ...localStep,
                                name: e.target.value,
                            })
                        }
                    /></h3>


                </div>
                <div>
                    <h3 className="mx-4">{"Conditions"}</h3>
                    <div className="card-body"
                    >
                        <div className={"line-in-middle"}>
                            {localStep.conditions.map((c, idx) => {
                                return (
                                    <div key={c.id} className="card mb-4 py-0 p-1">
                                        <div className="d-flex justify-content-between">
                                            {/* Condition left side */}
                                            <div className="d-flex align-content-center col-11">
                                                <div className="col-6">
                                                    <Select
                                                        menuPortalTarget={document.body}
                                                        styles={styleSelect}
                                                        name="condition type select"
                                                        value={
                                                            condition_types.find((option) => option.value === c.type) || ""
                                                        }
                                                        options={condition_types}
                                                        classNamePrefix="select"
                                                        onChange={(choice) =>
                                                            updateCondition("type", c, {target: {value: choice.value}})
                                                        }
                                                    />
                                                </div>

                                                {/* Operator / Value fields */}
                                                <div className="d-flex align-content-center">
                                                    {/* Some conditions have an "operator" dropdown */}
                                                    {c.type === "item_description" ||
                                                    c.type === "item_cost_type" ||
                                                    c.type === "item_gl" ? (
                                                        <div className="col-6 p-0 m-0">
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={styleSelect}
                                                                name="operator select"
                                                                value={{
                                                                    value: c.operator,
                                                                    label: data.t.read(`rule.${c.operator}`),
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "contain",
                                                                        label: data.t.read("rule.contain")
                                                                    },
                                                                    {
                                                                        value: "not_contain",
                                                                        label: data.t.read("rule.not_contain")
                                                                    },
                                                                    {value: "equal", label: data.t.read("rule.equal")},
                                                                    {
                                                                        value: "different",
                                                                        label: data.t.read("rule.different")
                                                                    },
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateCondition("operator", c, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    ) : null}

                                                    {c.type === "line_item_amount" ? (
                                                        <div className="col-6 p-0 m-0">
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={styleSelect}
                                                                name="operator select"
                                                                value={{
                                                                    value: c.operator,
                                                                    label: data.t.read(`rule.${c.operator}`),
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "greater",
                                                                        label: data.t.read("rule.greater")
                                                                    },
                                                                    {value: "lower", label: data.t.read("rule.lower")},
                                                                    {
                                                                        value: "equals",
                                                                        label: data.t.read("rule.equals")
                                                                    },
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateCondition("operator", c, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    ) : null}

                                                    <div className="align-items-center d-flex">
                                                        {/* Billable / Non-billable */}
                                                        {c.type === "invoice_billable" ? (
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={styleSelect}
                                                                name="value select"
                                                                value={{
                                                                    value: c.value,
                                                                    label: data.t.read(
                                                                        c.value === "1" ? "rule.billable" : "rule.non_billable"
                                                                    ),
                                                                }}
                                                                options={[
                                                                    {value: "1", label: data.t.read("rule.billable")},
                                                                    {
                                                                        value: "0",
                                                                        label: data.t.read("rule.non_billable")
                                                                    },
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateCondition("value", c, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        ) : c.type === "user_has_role" ? (
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                required
                                                                styles={styleSelect}
                                                                name="user_has_role"
                                                                value={
                                                                    c.role
                                                                        ? {
                                                                            value: c.role,
                                                                            label: data.t.read(
                                                                                "role_" + c.role.toLowerCase()
                                                                            ),
                                                                        }
                                                                        : null
                                                                }
                                                                options={data.config.roles.map((r) => ({
                                                                    value: r,
                                                                    label: data.t.read("role_" + r.toLowerCase()),
                                                                }))}
                                                                placeholder={data.t.read("client_table.role").toLowerCase()}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateCondition("role", c, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        ) : c.type === "invoice_custom" ? (
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={styleSelect}
                                                                name="invoice custom select"
                                                                value={{
                                                                    value: c.value,
                                                                    label: data.t.read(
                                                                        c.value === "1"
                                                                            ? "rule.invoice_custom"
                                                                            : "rule.invoice_not_custom"
                                                                    ),
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "1",
                                                                        label: data.t.read("rule.invoice_custom"),
                                                                    },
                                                                    {
                                                                        value: "0",
                                                                        label: data.t.read("rule.invoice_not_custom"),
                                                                    },
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateCondition("value", c, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        ) : c.type === "line_item_amount"
                                                        || c.type === "amount_lower" || c.type === "amount_greater" ? (
                                                            <MoneyInput
                                                                value={c.value}
                                                                required
                                                                onChange={(value) =>
                                                                    updateCondition("value", c, {
                                                                        target: {value: value},
                                                                    })
                                                                }
                                                                locale={`${data.t.lang}-${data.t.lang.toUpperCase()}`}
                                                                placeholder={data.t.read("rule.amount")}
                                                            />
                                                        ) : (
                                                            // Generic text input for other condition types
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control align-items-center"
                                                                placeholder={data.t.read("rule.value")}
                                                                value={c.value}
                                                                onChange={(e) => updateCondition("value", c, e)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Condition remove icon */}
                                            <div className="mr-4 align-content-center">
                                                {localStep.conditions.length > 1 && (
                                                    <FontAwesomeIcon
                                                        className=""
                                                        icon={faTrash}
                                                        type="button"
                                                        onClick={() => removeCondition(c)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {/* Add new condition button */}
                            <div className="card">
                                <div className="card-body">
                                    <div type="button" onClick={() => addCondition()}>
                                        <FontAwesomeIcon icon={faPlus}/> {"Condition"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="mx-4">{"Actions"}</h3>
                    <div className="card-body"
                        // style={{maxHeight: "47vh", overflowY: "auto", scrollbarWidth: "none"}}
                    >
                        <div className={"line-in-middle"}>
                            {localStep.actions.map((action, idx) => (
                                <div key={idx} className="card mb-4 pt-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="p-2 col-11">
                                            {/* Action Type */}
                                            <Select
                                                styles={actionSelect}
                                                isDisabled={
                                                    action.type === "request_approval" &&
                                                    !Utils.userHasPageAccess(data, "approval")
                                                }
                                                name="action type select"
                                                value={
                                                    action_types.find((option) => option.value === action.type) || {
                                                        value: action.type,
                                                        label: data.t.read(`rule.${action.type}`),
                                                    }
                                                }
                                                options={action_types}
                                                classNamePrefix="select"
                                                onChange={(choice) =>
                                                    updateAction("type", action, {
                                                        target: {value: choice.value},
                                                    })
                                                }
                                            />

                                            {/* Depending on action.type, show relevant sub-fields */}
                                            <div className="d-flex mt-4 col-12 mb-4 flex-wrap">
                                                {action.type === "achat_and_payment" && (
                                                    <div className="border rounded p-2 col-12">
                                                        {/* Payment mode */}
                                                        <div className="row mb-2">
                                                            <div className="col-3 align-self-center">
                                                                {data.t.read("payment_mode")}
                                                            </div>
                                                            <div className="col-9">
                                                                <Select
                                                                    required
                                                                    styles={styleSelect}
                                                                    name="payment_mode"
                                                                    value={action.payment_mode || ""}
                                                                    options={
                                                                        data.config.payment_modes
                                                                            ? data.config.payment_modes.map((p) => ({
                                                                                value: p.id,
                                                                                label: p.name,
                                                                            }))
                                                                            : []
                                                                    }
                                                                    placeholder={data.t.read("payment_mode")}
                                                                    classNamePrefix="select"
                                                                    onChange={(choice) =>
                                                                        updateAction("payment_mode", action, {
                                                                            target: {value: choice},
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Bank */}
                                                        <div className="row">
                                                            <div className="col-3 align-self-center">
                                                                {data.t.read("bank")}
                                                            </div>
                                                            <div className="col-9">
                                                                <Select
                                                                    required
                                                                    styles={styleSelect}
                                                                    name="bank"
                                                                    value={action.bank || ""}
                                                                    options={
                                                                        data.config.banks
                                                                            ? data.config.banks.map((p) => ({
                                                                                value: p.key,
                                                                                label: p.value,
                                                                            }))
                                                                            : []
                                                                    }
                                                                    placeholder={data.t.read("bank")}
                                                                    classNamePrefix="select"
                                                                    onChange={(choice) =>
                                                                        updateAction("bank", action, {
                                                                            target: {value: choice},
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {action.type === "request_approval" && (
                                                    <div className="col-12">
                                                        <Select
                                                            required
                                                            styles={actionSelect}
                                                            isDisabled={
                                                                action.type === "request_approval" &&
                                                                !Utils.userHasPageAccess(data, "approval")
                                                            }
                                                            name="approvers"
                                                            value={
                                                                approvers
                                                                    .filter((a) => a.id_user === action.value)
                                                                    .map(approverMap) ?? null
                                                            }
                                                            options={approvers.map(approverMap)}
                                                            placeholder={data.t.read("approver")}
                                                            classNamePrefix="select"
                                                            onChange={(choice) =>
                                                                updateAction("value", action, {
                                                                    target: {value: choice.value},
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                {action.type === "change_status" && (
                                                    <div className="col-12">
                                                        <Select
                                                            required
                                                            styles={actionSelect}
                                                            name="invoice status select"
                                                            value={{
                                                                value: action.value,
                                                                label: data.t.read("status." + (action.value || "").toLowerCase()),
                                                            }}
                                                            options={data.config.invoice_status.map((tmp) => ({
                                                                value: tmp,
                                                                label: data.t.read("status." + tmp.toLowerCase()),
                                                            }))}
                                                            placeholder={data.t.read("rule.status")}
                                                            classNamePrefix="select"
                                                            onChange={(choice) =>
                                                                updateAction("value", action, {
                                                                    target: {value: choice.value},
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                {action.type === "change_item_detail" && (
                                                    <div className="d-flex col-12 align-items-center mt-1">
                                                        <div className="col-4">
                                                            <Select
                                                                styles={actionSelect}
                                                                name="field select"
                                                                value={{
                                                                    value: action.field,
                                                                    label:
                                                                        action.field === "Description"
                                                                            ? "Description"
                                                                            : data.t.read(
                                                                                action.field === "GLNatural"
                                                                                    ? "gl_natural"
                                                                                    : action.field === "GLOffice"
                                                                                        ? "office"
                                                                                        : "invoice.cost_type"
                                                                            ),
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "GLNatural",
                                                                        label: data.t.read("gl_natural")
                                                                    },
                                                                    {value: "GLOffice", label: data.t.read("office")},
                                                                    {
                                                                        value: "CostType",
                                                                        label: data.t.read("cost_type")
                                                                    },
                                                                    {value: "Description", label: "Description"},
                                                                ]}
                                                                classNamePrefix="select"
                                                                onChange={(choice) =>
                                                                    updateAction("field", action, {
                                                                        target: {value: choice.value},
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-8">
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control"
                                                                placeholder={action.field}
                                                                value={action.value}
                                                                onChange={(e) =>
                                                                    updateAction("value", action, e)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {action.type === "send_email" && (
                                                    <div className="col-12 row align-items-center">
                                                        <div className="pl-3 col-6">
                                                            <input
                                                                type="email"
                                                                required
                                                                className="form-control"
                                                                placeholder={data.t.read("rule.email")}
                                                                value={action.value}
                                                                onChange={(e) =>
                                                                    updateAction("value", action, e)
                                                                }
                                                            />
                                                        </div>
                                                        <label className="font-xsmall ml-1">
                                                            Ex: me@my-company.com
                                                        </label>
                                                    </div>
                                                )}

                                                {action.type === "change_currency" && (
                                                    <div className="col-12 row">
                                                        <div className="col-4">
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control"
                                                                placeholder={data.t.read("rule.currency")}
                                                                value={action.value}
                                                                onChange={(e) =>
                                                                    updateAction("value", action, e)
                                                                }
                                                            />
                                                        </div>
                                                        <label className="col-8 font-xsmall">
                                                            Ex: CAD
                                                        </label>
                                                    </div>
                                                )}

                                                {action.type === "split_items" && (
                                                    <div className="col-12">
                                                        {Array.isArray(action.value) &&
                                                            action.value.map((line) => (
                                                                <div
                                                                    key={"l-" + line.id}
                                                                    className="d-flex mb-2 p-1 flex-wrap"
                                                                >
                                                                    <div>
                                                                        {action.value.length > 1 && (
                                                                            <FontAwesomeIcon
                                                                                className="mr-2 mt-1"
                                                                                icon={faMinusSquare}
                                                                                type="button"
                                                                                color="red"
                                                                                onClick={() => removeSplitItemValue(action, line)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className="row border p-1 col">
                                                                        <div className="col-12">
                                                                            <div className="row mb-1">
                                                                                <div className="col-3 font-weight-bold">
                                                                                    {line.id === 1
                                                                                        ? data.t.read("rule.a_line_containing")
                                                                                        : data.t.read("rule.and_add_line")}
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <div
                                                                                        className="input-group input-group-sm">
                                                                                        <div
                                                                                            className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text">%</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="number"
                                                                                            min="-100"
                                                                                            max="1000"
                                                                                            value={line.percent}
                                                                                            onChange={(e) =>
                                                                                                updateSplitItemValue(action, line, "percent", e)
                                                                                            }
                                                                                            style={{maxWidth: "100%"}}
                                                                                            className="form-control"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    {data.t.read("rule.of_amount")},
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-1">
                                                                                <div className="col-3">
                                                                                    {data.t.read("rule.up_to_max_of")}
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <div
                                                                                        className="input-group input-group-sm">
                                                                                        <div
                                                                                            className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text">$</span>
                                                                                        </div>
                                                                                        <input
                                                                                            type="number"
                                                                                            value={line.maxAmount}
                                                                                            style={{maxWidth: "100%"}}
                                                                                            onChange={(e) =>
                                                                                                updateSplitItemValue(
                                                                                                    action,
                                                                                                    line,
                                                                                                    "maxAmount",
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            className="form-control"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5">
                                                                                    ({data.t.read("rule.leave_empty_for_unlimited")})
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-1">
                                                                                <div className="col-3">
                                                                                    {data.t.read("rule.and_replace")}
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <input
                                                                                        type="number"
                                                                                        min="-100"
                                                                                        max="1000"
                                                                                        value={line.updateCharacterAmount}
                                                                                        onChange={(e) =>
                                                                                            updateSplitItemValue(
                                                                                                action,
                                                                                                line,
                                                                                                "updateCharacterAmount",
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        style={{maxWidth: "100%"}}
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    {data.t.read("rule.character_at")}
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <Select
                                                                                        styles={styleSelect}
                                                                                        name="replace at the start select"
                                                                                        value={{
                                                                                            value: line.replaceAtTheStart,
                                                                                            label: data.t.read(
                                                                                                line.replaceAtTheStart
                                                                                                    ? "rule.the_start"
                                                                                                    : "rule.the_end"
                                                                                            ),
                                                                                        }}
                                                                                        options={[
                                                                                            {
                                                                                                value: false,
                                                                                                label: data.t.read("rule.the_end"),
                                                                                            },
                                                                                            {
                                                                                                value: true,
                                                                                                label: data.t.read("rule.the_start"),
                                                                                            },
                                                                                        ]}
                                                                                        classNamePrefix="select"
                                                                                        onChange={(choice) =>
                                                                                            updateSplitItemValue(
                                                                                                action,
                                                                                                line,
                                                                                                "replaceAtTheStart",
                                                                                                {
                                                                                                    target: {value: choice.value},
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-1">
                                                                                <div className="col-3">
                                                                                    {data.t.read("rule.of")}
                                                                                </div>
                                                                                <div className="col-3">
                                                                                    <Select
                                                                                        styles={styleSelect}
                                                                                        name="update info select"
                                                                                        value={{
                                                                                            value: line.updateInfo || "",
                                                                                            label: line.updateInfo || "--",
                                                                                        }}
                                                                                        options={[
                                                                                            {value: "", label: "--"},
                                                                                            {
                                                                                                value: "GLNatural",
                                                                                                label: "GLNatural"
                                                                                            },
                                                                                            {
                                                                                                value: "GLOffice",
                                                                                                label: "GLOffice"
                                                                                            },
                                                                                            {
                                                                                                value: "CostType",
                                                                                                label: "CostType"
                                                                                            },
                                                                                        ]}
                                                                                        classNamePrefix="select"
                                                                                        onChange={(choice) =>
                                                                                            updateSplitItemValue(
                                                                                                action,
                                                                                                line,
                                                                                                "updateInfo",
                                                                                                {
                                                                                                    target: {value: choice.value},
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                {line.updateInfo && line.updateInfo.length > 0 && (
                                                                                    <>
                                                                                        <div className="col-3">
                                                                                            {data.t.read("rule.by")}
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                            <input
                                                                                                type="text"
                                                                                                required
                                                                                                className="form-control form-control-sm"
                                                                                                placeholder="Value"
                                                                                                value={line.updateInfoValue}
                                                                                                onChange={(e) =>
                                                                                                    updateSplitItemValue(
                                                                                                        action,
                                                                                                        line,
                                                                                                        "updateInfoValue",
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={() => addSplitItemValue(action)}
                                                            >
                                                                + {data.t.read("rule.line")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}

                                                {action.type === "change_all_totals" && (
                                                    <div className="input-group-prepend ml-3">
                                                        <input
                                                            type="number"
                                                            min="-100"
                                                            max="100"
                                                            required
                                                            value={action.value}
                                                            onChange={(e) => updateAction("value", action, e)}
                                                            style={{maxWidth: "100%"}}
                                                            className="form-control"
                                                        />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                )}

                                                {action.type === "change_user" && (
                                                    <div className="input-group-prepend col-12">
                                                        <input
                                                            type="text"
                                                            value={action.value}
                                                            className="form-control"
                                                            placeholder={data.t.read("user")}
                                                            onChange={(e) => updateAction("value", action, e)}
                                                        />
                                                    </div>
                                                )}

                                                {action.type === "change_item_description" && (
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            value={action.value}
                                                            required
                                                            className="form-control"
                                                            placeholder="Description"
                                                            onChange={(e) => updateAction("value", action, e)}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        {/* Action remove icon */}
                                        <div className="mr-4 align-content-center">
                                            {localStep.actions.length > 1 && (
                                                <FontAwesomeIcon
                                                    className=""
                                                    icon={faTrash}
                                                    type="button"
                                                    onClick={() => removeAction(action)}
                                                />
                                            )}
                                        </div>

                                    </div>

                                </div>
                            ))}

                            {/* Button: Add a new Action object in this selectedStep */}
                            <div className="card">
                                <div className="card-body">
                                    <div type="button" onClick={() =>
                                        updateAction("addAction", null, {target: {value: null}})
                                    }>
                                        <FontAwesomeIcon icon={faPlus}/> {"Action"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <span className="d-flex justify-content-between">
                                        <button type="button" className={"btn btn-outline-dark"}
                                                onClick={onCancel}>
                                            {data.t.read("cancel")}
                                        </button>
                                        <button type="submit" className="btn btn-primary"
                                        >
                                            {data.t.read("save")}
                                        </button>
                                    </span>

            </form>
        </div>
    );
};

export default WorkflowEditStepModal;
