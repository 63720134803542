import React, { useState, useRef, useEffect } from "react";
import Utils from "../utils/utils";
const MaskedCardNumberInput = ({ onChange, value = "", className = "" }) => {
    const inputRef = useRef(null);
    const cursorPositionRef = useRef(0); // Sauvegarde la position du curseur

    
    const [cardNumber, setCardNumber] = useState(value ? Utils.decryptCardNumber(value) : "");
    const [isFocused, setIsFocused] = useState(false);

   

    // Gère la saisie de l'utilisateur
    const handleChange = (e) => {
        cursorPositionRef.current = e.target.selectionStart; // Sauvegarde la position du curseur
        const rawValue = e.target.value.replace(/\D/g, ""); // Supprime tout sauf les chiffres
        setCardNumber(rawValue);
        if (onChange) {
            onChange(Utils.encryptCardNumber(rawValue)); // Passe la vraie valeur au parent
        }
    };

    // Restaure la position du curseur après mise à jour du `state`
    useEffect(() => {
        if(cardNumber.length == 5 || cardNumber.length == 9 || cardNumber.length == 13){
            return;
        }
        if (inputRef.current) {
            inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
        }
    }, [cardNumber]);

    return (
        <input
            ref={inputRef}
            type="text"
            value={isFocused ? Utils.formatCardNumberWithSpaces(cardNumber) : Utils.getMaskedDisplayValue(cardNumber)}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)} // Affiche la vraie valeur
            onBlur={() => setIsFocused(false)} // Applique le masquage quand l'utilisateur quitte
            maxLength={19} // 16 chiffres + 3 espaces
            placeholder="•••• •••• •••• 1234"
            className={className}
        />
    );
};

export default MaskedCardNumberInput;
