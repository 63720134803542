import {useEffect, useState} from "react";
import axios from "axios";
import iconv from 'iconv-lite';
import closeIcon2 from '../assets/img/icon-close2.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft,faAngleRight,faBars,faPlusSquare,faSpinner,faSync,faTimes,faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import FormTextAreaAutoSaved from './form-text-area-auto-saved';
import FormTextField from './form-textfield';
import Modal from './modal';
import Checkbox from "./checkbox";
import CustomResizable from './custom-resizable';
import Utils from "../utils/utils";

import {fetch} from '../utils/api-wrapper';
import Switch from "react-switch";
import SupplierForm from './supplier-form';
import LogHistory from './log-history';

const Transaction = ({ data, statement_id, selected, close, account_status }) => {
    const [statement, setStatement] = useState();
    const source = axios.CancelToken.source();
    const [pdfBuff, setPdfBuff] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [transaction, setTransaction] = useState(selected);
    const [currentView, setCurrentView] = useState("data");
    const [saving, setSaving] = useState(false);
    const [showDisapproval, setShowDisapproval] = useState(false);
    const [validating, setValidating] = useState(false);
    const [causeDenial, setCauseDenial] = useState(null);
    const [action, setAction] = useState();
    const [savingError, setSavingError] = useState(null);
    const [showConfirmRestartProcess, setShowConfirmRestartProcess] = useState("false");
    const [confirmDeleteViewId, setConfirmDeleteViewId] = useState(null);
    const [billablesSelected, setBillablesSelected] = useState([]);
    const [nonBillablesSelected, setNonBillablesSelected] = useState([]);
    const [confirmDeleteItems, setConfirmDeleteItems] = useState(null);
    const [imputations, setImputations] = useState([]);
    const [rates, setRates] = useState({});
    const [clearSupplierRecommendation, setClearSupplierRecommendation] = useState(null);
    const [promptNewSupplier, setPromptNewSupplier] = useState(false);
    const [newSupplier, setNewSupplier] = useState({
        Name: "",
        Number: "",
        Index: "",
        GLNatural: "",
        CostType: "",
        IsBillable: false,
    });
    // make it configurable by client
    const taxeCodes = ["T_IN", "TVH_IN", "N_IN", "TPS_IN", "TVH-ON_IN", "BC_IN"]
                        .filter(t => (data.config.type === "legal" || t !== "TVH-ON_IN"))
                        .filter(t => (data.config.engine === "demo" || t !== "BC_IN"))

    const taxesPercentage = {
        "T_IN": [0.09975,0.05], 
        "BC_IN": [0.07, 0.05],
        "TVH-ON_IN": [0.13,0],
        "TPS_IN": [0.05, 0],
        "N_IN": [0,0]
    }

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
            padding: data.isMobile ? "0" : baseStyles.padding,
        })
    };

    useEffect(() => () => source.cancel("Transaction unmounted"), []);
    useEffect(() => {
        fetchStatement()
        fetchImputations()
        fetchRates()
    }, []);

    const fetchStatement = () => {
        const searchData = `?statement_id=${encodeURIComponent(statement_id)}`;

        fetch(`/c_card/statement/${searchData}`, "GET", {},
            (response) => {
                setStatement(response.data.statement);
            }
            ,
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchStatement();
            }
            , false, source.token);
    }

    useEffect(() => {
        if (saving === true)
            updateTransaction()
    }
        , [saving])

    useEffect(() => {

        if (data.isMobile || ['mileage', 'perdiem'].includes(transaction.json?.type))
            return;
        const file_name = transaction.file_name ? transaction.file_name : `${statement_id}_${transaction.checksum}.pdf`;
        fetch(`/invoice/pdf/${file_name}`, "GET", {},

            response => {
                let buf = null;
                if (response.data.pdf != null) {
                    buf = iconv.encode(response.data.pdf, 'iso-8859-1');
                    let bb = new Blob([buf], { type: "application/pdf" });
                    buf = window.URL.createObjectURL(bb);
                    setPdfBuff(buf);
                }
            },
            null, false, source.token);

    }, [transaction.checksum])

    const fetchRates = () => {
        fetch("/expense_rates/", "GET", {}, (response) => {
            const fetchedRates = response.data?.rates;
            setRates(fetchedRates);
        } );
    };

    const fetchImputations = () => {
        const searchData = `?filters=${encodeURIComponent(JSON.stringify([]))}&sorting=${encodeURIComponent(JSON.stringify([]))}&start=${encodeURIComponent(0)}`;
        fetch(`/imputations/${searchData}`, "GET", {},
            response => {
                let fetchedImputations = response.data.imputations;
                setImputations(fetchedImputations);
            });
    }
    const showStatus = (value) => {
        return (value != null && value !== "") || data.config.frontend_invoice == null || !data.config.frontend_invoice.hide_na_fields ? "flex" : "none";
    }

    const updateTransaction = (action = 'update') => {
        setAction(action);
        
        fetch("/c_card/transaction/" + statement_id + "/" + transaction.number, "PUT", transaction,
            response => {
                setSaving(false);
                setTransaction(response.data.transaction);
                fetchStatement();
            },
            error => {
                setSaving(false);
                console.error(error);
                setSavingError(data.t.read("internal_saving_error"))
            })
    }

    const validateTransaction = () => {
        setValidating(true);
        const formData = new FormData();
        formData.append("transaction", JSON.stringify(transaction));
        fetch(`/c_card/statement/transaction/validate/${statement_id}/${transaction.checksum}`, "PUT", formData,
            response => {
                setValidating(false);
                setTransaction(response.data.transaction);
                getNextTransaction(true, true);
                fetchStatement();
            },
            error => {
                setValidating(false);
                console.error(error);
                setSavingError(`${data.t.read("internal_saving_error")} : ${data.t.read(error.response.data.message)}`);

            })
    }

    const restartProcess = () => {
        fetch(`/c_card/statement/transaction/restart-processing/${statement_id}/${transaction.checksum}`, "GET", {},
            response => {
                close();
            },
            null, false, source.token);
    }

    const isItemBillable = (item) => {
        return item.IsBillable;
    }

    const hasBillableItems = () => {
        return transaction.json && transaction.json.ListItem && transaction.json.ListItem.filter(item => isItemBillable(item)).length > 0;
    }

    const hasUnBillableItems = () => {
        return transaction.json && transaction.json.ListItem && transaction.json.ListItem.filter(item => !isItemBillable(item)).length > 0;
    }

    const addItem = (billable = false) => {
        const listItem = transaction.json.ListItem ?? [];

        const imputation = imputations.find(x => x.category === transaction?.json?.Category);
        listItem.push({
            _view_id: listItem.length,
            IsBillable: billable ? 1 : 0,
            Total: 0,
            ...(billable ? { CostType: imputation?.cost_type, MatterId: "" } : { GLNatural: imputation?.gl_natural }),
        });

        setTransaction(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
    }

    const toggleItemSelected = (section, item) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        let idx = list.findIndex(it => it._view_id === item._view_id);

        if (idx !== -1) {
            list.splice(idx, 1)
        }
        else {
            list.push(item);
        }

        section === "billable" ? setBillablesSelected([...list]) : setNonBillablesSelected([...list]);

    }

    const deleteItem = (viewId) => {
        const listItem = transaction.json.ListItem.filter(item => item._view_id !== viewId)
                                            .map((item, index) => ({
                                                                    ...item, // Spread the existing properties
                                                                    "_view_id": index,}
                                                                    ));
        setTransaction(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
        setConfirmDeleteViewId(null);
        setNonBillablesSelected([])
        setBillablesSelected([])
    }

    const confirmDeleteAction = (section) => {
        let list = section === "billable" ? billablesSelected : nonBillablesSelected;
        setConfirmDeleteItems(list);
        section === "billable" ? setBillablesSelected([]) : setNonBillablesSelected([]);
    }

    const deleteItems = () => {
        const listItem = transaction.json.ListItem.filter(item => !confirmDeleteItems.includes(item))
                                            .map((item, index) => ({
                                                                    ...item, // Spread the existing properties
                                                                    "_view_id": index,}
                                                                    ));
        setTransaction(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem] } }));
        setSaving(true);
        setNonBillablesSelected([])
        setBillablesSelected([])
        setConfirmDeleteItems(null);
    }

    const recalculateTaxes = (listItem) => {
        let {Tax1, Tax2, Total, Tax, TotalWithTax} = {Tax1: 0, Tax2: 0, Total: 0, Tax: 0, TotalWithTax: 0}
        listItem.forEach(it => {
            const percentage1 = taxesPercentage[it.TaxCode]?.[0] ?? 0;
            const percentage2 = taxesPercentage[it.TaxCode]?.[1] ?? 0;
            Tax1 += Number((it.Total * percentage1).toFixed(2));
            Tax2 += Number((it.Total * percentage2).toFixed(2));
            Total += Number(it.Total);
            Tax = Number((Tax1 + Tax2).toFixed(2));
            TotalWithTax = Number((Total + Tax).toFixed(2));
        });
        return {Tax1, Tax2, Total, Tax, TotalWithTax};
    }

    const updateItem = (item, updateTaxes = false) => {
        let {Tax1, Tax2, Total, Tax, TotalWithTax} = transaction.json;
        const listItem = transaction.json.ListItem;
        const index = listItem.findIndex(it => it._view_id === item._view_id);
        listItem[index] = item;
       
        
        if (updateTaxes) {
            ({Tax1, Tax2, Total, Tax, TotalWithTax} = recalculateTaxes(listItem));
        }
        setTransaction(prev => ({ ...prev, json: { ...prev.json, ListItem: [...listItem], Tax1: Tax1, Tax2: Tax2, Total: Total, Tax: Tax, TotalWithTax: TotalWithTax } }));
        setSaving(true);
    }

    const toggleSelectAll = (sectionName) => {
        if (sectionName === "non-billable")
            setNonBillablesSelected(transaction.json.ListItem && transaction.json.ListItem.filter(item => !isItemBillable(item)).length !== nonBillablesSelected.length ? [...transaction.json.ListItem.filter(item => !isItemBillable(item))] : [])
        else
            setBillablesSelected(transaction.json.ListItem && transaction.json.ListItem.filter(item => isItemBillable(item)).length !== billablesSelected.length ? [...transaction.json.ListItem.filter(item => isItemBillable(item))] : [])
    }


    /**
     * when getting the next transaction we call ask to remove it from list 
     * @param {boolean} remove 
     * @param {boolean} forward 
     */
    const getNextTransaction = (remove = true, forward = true) => {
        let nextIndex;
        setBillablesSelected([])
        setNonBillablesSelected([])
        const indexCurrentInvoice = statement?.transactions.findIndex(
            xpen => xpen.checksum === transaction.checksum
        );

        if (forward === true) {
            if (indexCurrentInvoice + 1 <  statement?.transactions.length) {
                nextIndex = indexCurrentInvoice + 1
            }
            else {
                nextIndex = 0
            }
        }

        else if (forward === false) {
            if (indexCurrentInvoice - 1 >= 0)
                nextIndex = indexCurrentInvoice - 1;
            else
                nextIndex =  statement?.transactions.length - 1;
        }

        if (remove === true) {
            statement?.transactions.splice(indexCurrentInvoice, 1);
            nextIndex = indexCurrentInvoice;
        }

        if ( statement?.transactions.length > nextIndex) {
            setTransaction( statement?.transactions[nextIndex]);
        }
        else
            close();
    }

    const getNextTaxCode = (taxCode) => {
        let indexNextCode = taxeCodes.findIndex(el => el === taxCode) + 1;
        indexNextCode = indexNextCode >= taxeCodes.length ? 0 : indexNextCode;
        return taxeCodes[indexNextCode];
    }

    const updateTransactionStatus = (choice) => {
        if (choice.value === "Validated") {
            validateTransaction();
            return;
        }
        if (choice.value === "Approved") {
            approveTransaction();
            return;
        }
        if (choice.value === "Denied") {
            setShowDisapproval(true);
            return;
        }
        setTransaction(prev => ({ ...prev, status: choice.value }));
        setSaving(true);
    };

    const approveTransaction = () => {
        setTransaction(prev => ({ ...prev, status: 'Approved' }));
        setAction('approving');
        setSaving(true);
    }

    const disApproveTransaction = () => {
        setAction('disApproving');
        setTransaction(prev => ({
            ...prev,
            status: 'Denied',
            status_details: new Date().toLocaleString() + ' : ' + data.firstName + ' ' + data.lastName + ' : ' + causeDenial + '\n' + transaction?.status_details
        }
        ));
        setShowDisapproval(false);
        setSaving(true);
    }

    const supplierChosen = (sup, typedText) => {
        
        if (!sup) {
            setPromptNewSupplier(true);
            return;
        }

        const items = transaction.json.ListItem.map(it => ({
            ...it,
            GLNatural: [undefined, null, ''].includes(sup.gl_code) ? it.GLNatural : sup.gl_code,
            CostType: [undefined, null, ''].includes(sup.cost_type) ? it.CostType : sup.cost_type,
            IsBillable: [undefined, null, ''].includes(sup.IsBillable) ? it.IsBillable : sup.IsBillable
        }));

        setPromptNewSupplier(false);
        setTransaction(prev => ({ 
            ...prev, 
            json: { 
                ...prev.json,
                GLNatural: [undefined, null, ''].includes(sup.gl_code) ? prev.json.GLNatural : sup.gl_code,
                CostType: [undefined, null, ''].includes(sup.cost_type) ? prev.json.CostType : sup.cost_type,
                IsBillable: [undefined, null, ''].includes(sup.IsBillable) ? prev.json.IsBillable : sup.IsBillable,
                ListItem: items,
                ListSupplier: [
                    { ...prev.json.ListSupplier?.[0], Name: sup.name, Number: sup.number, Index: sup._id},
                    ...prev.json.ListSupplier?.slice(1)
                ]
            } 
        }))
        setSaving(true);
    }

    const updateType = (choice) => {
        setTransaction(prev => ({ ...prev, json: { ...prev.json, type: choice?.value, rate: choice.rate } }));
        setSaving(true);
    }

    const updateCategory = (choice) => {
        const imputation = imputations.find(x => x.category === choice.value);

        const updated_transaction = {
            ...transaction, json: {
                ...transaction.json, Category: choice.value,
                ...(transaction.json.ListItem && imputation && {
                    ListItem: transaction.json.ListItem.map(item => {
                        if ([false, null, 0].includes(item.IsBillable)) {
                            return {...item, GLNatural: imputation.gl_natural}; // Update GLNatural
                        } else if ([true, 1].includes(item.IsBillable)) {
                            return {...item, CostType: imputation.cost_type}; // Update CostType
                        }
                        return item;
                    }),
                }),
            },
        };

        setTransaction(updated_transaction);
        setSaving(true)
    }

    return transaction == null ?
        <div className="loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
        :
        <div className="invoice bg-white">
            {
                pdfBuff !== null &&
                <CustomResizable type="Invoice">
                    <div className="image-section position-relative">
                        <div className="d-flex main-tabs">
                            <div>
                                <div className="tab-title p-3 pointer " onClick={() => setMenuVisible(true)}>
                                    <FontAwesomeIcon icon={faBars} style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                            {
                                menuVisible &&
                                    <div className="left-menu-section">
                                        <div>
                                            <div className="d-flex main-tabs mb-4">
                                                <div className="tab-title text-secondary p-3 pointer" onClick={() => setMenuVisible(false)}>
                                                    <FontAwesomeIcon className="text-secondary" icon={faTimes} style={{ fontSize: "25px" }} />
                                                </div>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className="mock-link text-danger" >
                                                            {data.t.read("delete")}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                            <object className="pdf"  width="100%" height="100%" type="application/pdf" data={pdfBuff} aria-label="PDF"></object>
                    </div>
                </CustomResizable>
            }
            
            <div className={data.isMobile ? "content-section d-flex flex-column mb-5" : "content-section d-flex flex-column"}>
                <div className="d-flex main-tabs">
                    {
                            statement?.transactions &&  statement?.transactions.length > 1 &&
                        <div className='pointer icon-box'>
                            <FontAwesomeIcon disabled={saving} icon={faAngleLeft} className="fa-2x pointer" color='#F39800' onClick={() => getNextTransaction(false, false)} />
                        </div>
                    }
                    <div className={"flex-grow-1 p-3 tab-title " + (currentView === "data" ? "tab-selected" : "")} onClick={() => setCurrentView("data")}>
                        {data.t.read("data")}
                    </div>
                    <div className={"flex-grow-1 p-3 tab-title " + (currentView === "history" ? "tab-selected" : "")} onClick={() => setCurrentView("history")}>
                        {data.t.read("history")}
                    </div>
                    <div className="p-3 pointer" onClick={() => close()}>
                        <img src={closeIcon2} alt="Close" />
                    </div>
                    {
                            statement?.transactions &&  statement?.transactions.length > 1 &&
                        <div className='pointer icon-box'>
                            <FontAwesomeIcon disabled={saving} icon={faAngleRight} className="fa-2x pointer" color='#F39800' onClick={() => getNextTransaction(false, true)} />
                        </div>
                    }
                </div>
                {
                currentView ==="data" && <div className="p-4 d-flex flex-column flex-grow-1">
                    <div className="row mb-1">
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 align-self-center font-weight-bold"}>
                            {data.t.read("invoice.status_txt")} :
                        </div>
                        <Select
                            name="status"
                            value={transaction.status ? { value: transaction.status, label: data.t.read(`status.${transaction.status.toLowerCase()}`) } : null}
                            options={data.config.invoice_status.map(s => ({ label: data.t.read(`status.${s.toLowerCase()}`), value: s }))}
                            styles={styleSelect}
                            className={data.isMobile ? "col-6 invoice-value pl-1" : "pl-1 col-3 invoice-value"}
                            classNamePrefix="select"
                            onChange={updateTransactionStatus}
                        />
                    </div>
                    <div className="row mb-1" hidden={!['mileage', 'perdiem'].includes(transaction.json?.type)}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            Type :
                        </div>
                        <Select
                            name="type"
                            value={transaction.json?.type ? { value: transaction.json?.type, label: data.t.read(transaction.json?.type) } : null}
                            options={[{ label: data.t.read('mileage'), value: 'mileage', rate: (rates?.mileage_details?.rate || 0.7) }, { label: data.t.read('perdiem'), value: 'perdiem', rate: (rates?.perdiem_details?.rate || 75) }]}
                            styles={styleSelect}
                            className={data.isMobile ? "col-6 invoice-value pl-1" : " pl-1 col-3 invoice-value"}
                            classNamePrefix="select"
                            onChange={updateType}
                            placeholder={'Type'}
                        />
                    </div>

                    <div className="row" hidden={transaction.json?.type !== 'mileage'}>
                        <div className={data.isMobile ? "col-12 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("address_origin")} :
                        </div>
                        <div className={data.isMobile ? "col-11 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField searchParams={{ limit: 5 }} hideNoResults={true} hideAddOperation={true} format="data" locked={validating || saving} value={transaction.json?.AddressOrigin ?? ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, AddressOrigin: val } })); setSaving(true) }} searchURL="/places/search/" />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.json?.type !== 'perdiem'}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("number_of_days")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="number" locked={validating || saving} value={transaction.json?.quantity} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, quantity: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.json?.type !== 'mileage'}>
                        <div className={data.isMobile ? "col-12 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("address_destination")} :
                        </div>
                        <div className={data.isMobile ? "col-11 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField searchParams={{ limit: 5 }} hideNoResults={true} hideAddOperation={true} format="data" locked={validating || saving} value={transaction.json?.AddressDestination ?? ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, AddressDestination: val } })); setSaving(true) }} searchURL="/places/search/" />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.json?.type !== 'mileage'}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            Distance :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="distance" locked={saving} value={transaction.json?.Distance ? transaction.json?.Distance : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Distance: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.json?.type !== 'mileage'}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("round_trip")} :
                        </div>
                        <Switch className='switch invoice-value ml-3'
                            checked={transaction.json?.isRoundTrip ?? false}
                            width={70}
                            onColor="#F39800"
                            onHandleColor="#2693e6"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            onChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, isRoundTrip: val } })); setSaving(true); }}
                        />
                    </div>

                    <div className="row" hidden={!['mileage', 'perdiem'].includes(transaction.json?.type)}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {`${data.t.read("rate")} / `} {transaction.json?.type === 'mileage' ? 'km' : data.t.read('day')} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={saving} value={transaction.json?.rate ?? null} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, rate: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.type !== 'p-card'}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.supplier_number")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="data" locked={saving} value={transaction.json?.ListSupplier?.[0]?.Number}
                                validateChange={val => { 
                                    setTransaction(prev => ({ 
                                        ...prev, 
                                        json: { 
                                            ...prev.json, 
                                            ListSupplier: [
                                                { ...prev.json.ListSupplier?.[0], Number: val },
                                                ...prev.json.ListSupplier?.slice(1)
                                            ]
                                        } 
                                    })); 
                                    setSaving(true) 
                                }}
                            />
                        </div>
                    </div>

                    <div className="row" hidden={transaction.type !== 'p-card'}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.supplier_name")} :
                        </div>

                        <div className="col-md-5 invoice-value">
                            <FormTextField format="data" locked={saving} value={transaction.json?.ListSupplier?.[0]?.Name} 
                            validateChange={val => { 
                                setTransaction(prev => ({ 
                                    ...prev, 
                                    json: { 
                                        ...prev.json, 
                                        ListSupplier: [
                                            { ...prev.json.ListSupplier?.[0], Name: val },
                                            ...prev.json.ListSupplier?.slice(1)
                                        ]
                                    } 
                                })); 
                                setSaving(true) 
                            }}
                            searchURL="/suppliers/search/" searchCallback={(sup, typedText) => supplierChosen(sup, typedText)} />
                        </div>
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.InvoiceDate) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.billing_date")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="date" locked={saving} value={transaction.json?.InvoiceDate ? transaction.json?.InvoiceDate : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, InvoiceDate: val } })); setSaving(true) }} />
                        </div>
                    </div>
                    <div className="row" style={{ display: showStatus(transaction.json?.InvoiceNo) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.invoice_number")}:
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="data" locked={saving} value={transaction.json?.InvoiceNo} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, InvoiceNo: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.Currency) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.currency")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="data" locked={saving} value={transaction.json?.Currency} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Currency: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row mb-1" style={{display: showStatus(transaction.json?.Category)}}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("category")} :
                        </div>
                            <Select
                                name="Category"
                                value={{value: transaction.json?.Category ?? data.t.read("n_a"), label: transaction.json?.Category ?? data.t.read("n_a")}}
                                options={[
                                    {value: data.t.read("n_a"), label: data.t.read("n_a")},
                                    ...imputations.map((imputation) => {
                                        return {value: imputation.category, label: imputation.category}
                                    })]
                                }
                                styles={styleSelect}
                                className={data.isMobile ? "col-6 invoice-value pl-1" : "pl-1 col-3 invoice-value"}
                                classNamePrefix="select"
                                onChange={updateCategory}
                                placeholder={'Category'}
                            />
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.Total) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.total")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={saving} value={transaction.json?.Total ? transaction.json?.Total : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Total: val, TotalWithTax: val + (transaction.json?.Tax?? 0) } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.Tax) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.tax")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={true} value={transaction.json?.Tax ? transaction.json?.Tax : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Tax: val, TotalWithTax: val + (transaction.json?.Total?? 0) } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.TotalWithTax) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("invoice.total_with_tax")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={true} value={transaction.json?.TotalWithTax ? transaction.json?.TotalWithTax : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, TotalWithTax: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" style={{ display: showStatus(transaction.json?.Tip) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            {data.t.read("tips")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={saving} value={transaction.json?.Tip ? transaction.json?.Tip : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Tip: val } })); setSaving(true) }} />
                        </div>
                    </div>

                    <div className="row" hidden={transaction?.type === 'p-card' }>
                        <div className={data.isMobile ? "col-6 font-weight-bold align-self-center" : "col-3 font-weight-bold align-self-center"}>
                            Xpense {data.t.read("invoice.total")} :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={true} value={transaction.json?.BigTotalPayed ? transaction.json?.BigTotalPayed : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, BigTotalPayed: val } })); setSaving(true) }} />
                        </div>
                    </div>
                    <div className="mt-5" hidden={data.config?.frontend_invoice?.hide_cost_details_table}>
                        <h4 className="hidden-actions-container d-flex justify-content-between">
                            <span>
                                {data.t.read("invoice.cost_details")}
                                <span className="hidden-actions">
                                    <button disabled={saving} className="btn btn-sm btn-link" onClick={() => addItem(true)}>
                                        <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                    </button>
                                </span>
                            </span>
                            <div className="text-right">
                                <button className={"btn btn-secondary btn-sm btn-xs " + (billablesSelected.length > 0 ? "" : "faded")} onClick={() => confirmDeleteAction("billable")} >{data.t.read("delete")}</button>
                            </div>
                        </h4>
                        {hasBillableItems() &&
                            <table className={data.isMobile ? "table invoice-items-table mt-3 font-xsmall" : "table invoice-items-table mt-3 font-xsmall"}>
                                <thead>
                                    <tr>
                                        <th style={{width: data.isMobile ? '20%' : '5%'}}>
                                            <Checkbox disabled={saving} isChecked={transaction.json.ListItem.filter(item => isItemBillable(item)).length === billablesSelected.length && billablesSelected.length > 0} onChange={e => toggleSelectAll("billable")} />
                                        </th>
                                        <th className="col-3" >Description</th>
                                        <th className="col-3" hidden={data.isMobile}>{data.t.read("invoice.matter_id")}</th>
                                        <th className="col-2" hidden={data.isMobile} >{data.t.read("invoice.cost_type")}</th>
                                        <th className="col-2" hidden={data.isMobile} >{data.t.read("invoice.tax_code")}</th>
                                        <th className="col-2" >{data.t.read("invoice.amount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transaction.json.ListItem.filter(item => isItemBillable(item)).map((item) => {
                                            return <tr key={"invoice-item-" + item._view_id}>
                                                <td onClick={e => e.stopPropagation()} className="align-middle" >
                                                    <Checkbox isChecked={billablesSelected.findIndex(it => it._view_id === item._view_id) > -1} onChange={e => toggleItemSelected("billable", item)} />
                                                </td>
                                                <td className="align-middle">
                                                    <FormTextField format="data" locked={saving} value={item.Description} validateChange={val => updateItem({ ...item, Description: val })} />
                                                </td>
                                                <td className="align-middle" hidden={data.isMobile}>
                                                    <FormTextField format="data" locked={saving} value={item.MatterId} validateChange={val => updateItem({ ...item, MatterId: val })} />
                                                </td>
                                                <td className="align-middle" hidden={data.isMobile}>
                                                    <FormTextField format="data" locked={saving} value={item.CostType} validateChange={val => updateItem({ ...item, CostType: val })} />
                                                </td>
                                                <td className="align-middle" hidden={data.isMobile}>
                                                    <button onClick={() => updateItem({ ...item, TaxCode: getNextTaxCode(item.TaxCode ?? "N_IN" ) }, true)}>{item.TaxCode ?? "N_IN"}</button>
                                                </td>
                                                
                                                <td>
                                                    <div className="position-relative">
                                                        <FormTextField format="money" locked={saving} value={item.Total} validateChange={val => updateItem({ ...item, Total: val }, true)} />
                                                        <div className="item-actions">
                                                            <button className="btn btn-sm btn-link" onClick={() => setConfirmDeleteViewId(item._view_id)}>
                                                                <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>}
                    </div>

                    <div className="mt-5">
                        <h4 className="hidden-actions-container d-flex justify-content-between">
                            <span>
                                {data.t.read("invoice.gl_details")}
                                <span className="hidden-actions">
                                    <button disabled={saving} className="btn btn-sm btn-link" onClick={() => addItem(false)}>
                                        <FontAwesomeIcon className="text-success" icon={faPlusSquare} />
                                    </button>
                                </span>
                            </span>
                            <div className="text-right">
                                <button className={"btn btn-secondary btn-sm btn-xs " + (nonBillablesSelected.length > 0 ? "" : "faded")} onClick={() => confirmDeleteAction("non-billable")} >{data.t.read("delete")}</button>
                            </div>
                        </h4>
                        {hasUnBillableItems() &&
                            <table className={data.isMobile ? "table invoice-items-table mt-3 font-xsmall" : "table invoice-items-table mt-3 font-xsmall"}>
                                <thead>
                                    <tr>
                                        <th style={{width: data.isMobile ? '20%' : '5%'}}>
                                            <Checkbox disabled={saving} isChecked={transaction.json.ListItem.filter(item => !isItemBillable(item)).length === nonBillablesSelected.length && nonBillablesSelected.length > 0} onChange={e => toggleSelectAll("non-billable")} />
                                        </th>
                                        <th className="col-3" >Description</th>
                                        <th hidden={data.isMobile} className="col-3">{data.t.read("invoice.gl_nat")}</th>
                                        <th className="col-3" hidden={data.isMobile}>{data.t.read("invoice.tax_code")}</th>
                                        <th className="col-3">{data.t.read("invoice.amount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transaction.json.ListItem.filter(item => !isItemBillable(item)).map((item) => {
                                            return <tr key={"invoice-item-" + item._view_id}>
                                                <td onClick={e => e.stopPropagation()} className="align-middle">
                                                    <Checkbox isChecked={nonBillablesSelected.findIndex(it => it._view_id === item._view_id) > -1} onChange={e => toggleItemSelected("non-billable", item)} />
                                                </td>
                                                <td className="align-middle" >
                                                    <FormTextField format="data" locked={saving} value={item.Description} validateChange={val => updateItem({ ...item, Description: val })} />
                                                </td>

                                                <td className="align-middle" hidden={data.isMobile}>
                                                    <FormTextField format="data" locked={saving} value={item.GLNatural} validateChange={val => updateItem({ ...item, GLNatural: val })} />
                                                </td>
                                                <td className="align-middle" hidden={data.isMobile}>
                                                    <button onClick={() => updateItem({ ...item, TaxCode: getNextTaxCode(item.TaxCode ?? "N_IN" ) }, true)}>{item.TaxCode ?? "N_IN"}</button>
                                                </td>
                                                <td>
                                                    <div className="position-relative">
                                                        <FormTextField format="money" locked={saving} value={item.Total} validateChange={val => updateItem({ ...item, Total: val }, true)} />
                                                        <div className="item-actions">
                                                            <button className="btn btn-sm btn-link" onClick={() => setConfirmDeleteViewId(item._view_id)}>
                                                                <FontAwesomeIcon className="text-danger" icon={faWindowClose} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>}
                    </div>

                    <h4 className="mt-5" style={{ display: showStatus(transaction.json?.Tax1) === "flex" || showStatus(transaction.json?.Tax1) === "flex" ? "block" : "none" }}>{data.t.read("invoice.tax_details")}</h4>

                    <div className="row" style={{ display: showStatus(transaction.json?.Tax1) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold" : "col-2 font-weight-bold"}>
                            {data.t.read("invoice.tax")} 1 :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={saving} value={transaction.json?.Tax1 ? transaction.json.Tax1 : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Tax1: val, Tax: val + (transaction.json?.Tax2?? 0), TotalWithTax: val + (transaction.json?.Tax2?? 0) + (transaction.json?.Total?? 0) } })); setSaving(true) }} />
                        </div>
                    </div>
                    <div className="row" style={{ display: showStatus(transaction.json?.Tax2) }}>
                        <div className={data.isMobile ? "col-6 font-weight-bold" : "col-2 font-weight-bold"}>
                            {data.t.read("invoice.tax")} 2 :
                        </div>
                        <div className={data.isMobile ? "col-6 invoice-value" : "col-3 invoice-value"}>
                            <FormTextField format="money" locked={validating | saving} value={transaction.json?.Tax2 ? transaction.json.Tax2 : ""} validateChange={val => { setTransaction(prev => ({ ...prev, json: { ...prev.json, Tax2: val, Tax: val + (transaction.json?.Tax1?? 0), TotalWithTax: val + (transaction.json?.Tax1?? 0) + (transaction.json?.Total?? 0) } })); setSaving(true) }} />
                        </div>
                    </div>

                    <h4 className="mt-5">{data.t.read("comments")}</h4>
                    <div>
                        <FormTextAreaAutoSaved locked={validating || saving} value={transaction.status_details} validateChange={val => { setTransaction(prev => ({ ...prev, status_details: val })); setSaving(true) }} />
                    </div>

                    {
                        <div className="mt-5 d-flex justify-content-between">
                            <div>
                                <button hidden={['mileage', 'perdiem'].includes(transaction.json?.type)} className="btn btn-sm btn-secondary mr-2" onClick={() => setShowConfirmRestartProcess(true)}>
                                    {data.t.read("invoice.restart_processing")}
                                </button>
                            </div>
                            {account_status === 'Submitted' &&
                                <button className="btn btn-primary" disabled={validating | saving} onClick={() => validateTransaction()}>
                                    {data.t.read("validate")}
                                    {validating && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                </button>
                            }

                            {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) &&
                                <div className="text-right">
                                    <button className={"btn ${data.isMobile ? 'col-8' : ''}` m-1  btn-primary"} hidden={transaction.status !== "Need_Approval"}
                                        onClick={() => approveTransaction()}>
                                        {data.t.read("approve")}
                                        {saving && action === 'approving' && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                                    </button>

                                    <button className="btn ${data.isMobile ? 'col-8' : ''}` m-1 btn-danger " hidden={transaction.status !== "Need_Approval"} onClick={() => setShowDisapproval(true)}>
                                        {data.t.read("disapprove")}
                                    </button>
                                </div>
                            }

                        </div>
                    }
                </div>
                }
                {
                currentView === "history" && <LogHistory data={data} statement_id={statement_id} checksum={transaction.checksum} />
                }
            </div>
        
            
            {
                showConfirmRestartProcess === true &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setShowConfirmRestartProcess(false)} okAction={() => restartProcess()} okText={data.t.read("yes")} cancelText={data.t.read("cancel")}>
                    {data.t.read("invoice.restart_processing")} ?
                </Modal>

            }
            {
                confirmDeleteItems != null &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteItems()} okAction={() => deleteItems()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>

            }
            {
                confirmDeleteViewId != null &&
                <Modal title={data.t.read("confirm")} cancelAction={() => setConfirmDeleteViewId(null)} okAction={() => deleteItem(confirmDeleteViewId)} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                    {data.t.read("delete")} ?
                </Modal>

            }
            {
                savingError != null &&
                <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                    <div className="text-danger">
                        {savingError}
                    </div>
                </Modal>

            }
            {
                showDisapproval &&
                <Modal overflow="visible" title={data.t.read("denial_cause_placeholder")} cancelAction={() => setShowDisapproval(false)} okAction={() => disApproveTransaction()} okText={data.t.read("submit")} cancelText={data.t.read("cancel")}>
                    <input className='form-control' type="text" onChange={e => setCauseDenial(e.target.value)} />
                </Modal>
            }
            {
                promptNewSupplier &&
                <SupplierForm 
                    data={data}
                    onCancel={() => setPromptNewSupplier(false)}
                    onSave={(supplier) => supplierChosen(supplier, null)}
                />
            }
        </div>
}

export default Transaction;