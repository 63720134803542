import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Modal from './modal';
import { fetch } from '../utils/api-wrapper';

const SupplierForm = ({ data, onSave, onCancel}) => {
    const [valid, setValid] = useState(false);
    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
            padding: data.isMobile ? "0" : baseStyles.padding,
        })
    };

    const [newSupplier, setNewSupplier] = useState({
        Name: "",
        AltName: "",
        Number: "",
        GLNatural: "",
        CostType: "",
        Office: null,
        Address: "",
        Country: "",
        State: "",
        City: "",
        Zipcode: "",
    });
    const [offices, setOffices] = useState([]);
    const refreshOffices = () => {
        const sorting = [{ "id": "name", "desc": false }]
        const filters = [{ 'id': "id_client", 'value': data.id_client }];
        const searchData = `?filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(0)}`;
        fetch(`/offices/${searchData}`, "GET", {},
            response => {
                setOffices(response.data.offices);
            });
    }
    useEffect(() => {
        refreshOffices();
    }, []);

    useEffect(() => {
        console.log(newSupplier);
        console.log(newSupplier.Name.trim().length <= 2 ||
        newSupplier.Country?.trim().length <= 2 ||
        newSupplier.Zipcode?.replace(" ", "").length <= 2 ||
        newSupplier.Address?.trim().length <= 2 ||
        (data.config?.engine === 'juris' || newSupplier.Number.trim().length <= 2) ||
        newSupplier.GLNatural?.trim().length <= 1 ||
        (data.config?.engine !== 'juris' || (newSupplier.City?.replace(" ", "").length <= 1 || newSupplier.State?.replace(" ", "").length <= 1))
        );
        setValid(newSupplier.Name.trim().length > 2 &&
            newSupplier.Country?.trim().length > 2 &&
            newSupplier.Zipcode?.replace(" ", "").length > 2 &&
            newSupplier.Address?.trim().length > 2 &&
            (data.config?.engine === 'juris' || newSupplier.Number.trim().length > 2) &&
            newSupplier.GLNatural?.trim().length > 1 &&
            (data.config?.engine !== 'juris' || (newSupplier.City?.replace(" ", "").length <= 1 || newSupplier.State?.replace(" ", "").length <= 1))
        );
    }, [newSupplier]);
    
    const confirmNewSupplier = () => {
        
        let names = [newSupplier.Name];

        if (newSupplier.AltName.trim().length > 0) {
            names.push(newSupplier.AltName);
        }

        let formData = new FormData();

        formData.append("names", JSON.stringify(names));
        formData.append("zipcode", newSupplier.Zipcode);
        formData.append("country", newSupplier.Country);
        formData.append("state", newSupplier.State);
        formData.append("city", newSupplier.City);
        formData.append("address", newSupplier.Address);
        formData.append("gl_code", newSupplier.GLNatural);
        formData.append("cost_type", newSupplier.CostType);
        formData.append("active", newSupplier.Active);
        formData.append("supplier_number", newSupplier.Number);
        formData.append("tax_code", newSupplier.TaxCode);
        formData.append("is_billable", newSupplier.IsBillable);
        formData.append('office', JSON.stringify(newSupplier.Office))

        fetch("/suppliers/", "post", formData,
            response => {
                let supplier = {}
                supplier.name = newSupplier.Name;
                supplier._id = response.data.id;
                supplier.country = newSupplier.Country;
                supplier.zipcode = newSupplier.Zipcode;
                supplier.state = newSupplier.State;
                supplier.office = newSupplier.Office;
                supplier.city = newSupplier.City;
                supplier.number = newSupplier.Number;
                supplier.IsBillable = newSupplier.IsBillable === "true";
                supplier.tax_code = newSupplier.TaxCode;
                supplier.gl_code = newSupplier.GLNatural;
                supplier.cost_type = newSupplier.CostType;
                onSave(supplier);
            }
        );
    }
    

    return <Modal 
            title={data.t.read("new_supplier")} 
            cancelAction={onCancel} 
            okAction={confirmNewSupplier} 
            okText={data.t.read("save")} 
            cancelText={data.t.read("cancel")}
            okIsSubmit={false}
            invalid={!valid}
            >
                <div className='mb-2'>
                    <input placeholder={data.t.read("invoice.supplier_name")} className='form-control' type="text" value={newSupplier.Name} onChange={e => setNewSupplier({ ...newSupplier, Name: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("alt_name") + " (" + data.t.read("optional") + ")"} className='form-control' type="text" value={newSupplier.AltName} onChange={e => setNewSupplier({ ...newSupplier, AltName: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input readOnly={data.config.engine === "juris"} placeholder={data.t.read("supplier_code")} className='form-control' type="text" value={newSupplier.Number} onChange={e => setNewSupplier({ ...newSupplier, Number: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("supplier_gl")} className='form-control' type="text" value={newSupplier.GLNatural} onChange={e => setNewSupplier({ ...newSupplier, GLNatural: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("invoice.cost_type")} className='form-control' type="text" value={newSupplier.CostType} onChange={e => setNewSupplier({ ...newSupplier, CostType: e.target.value })} />
                </div>
                {data.config.engine === "juris" &&
                    <div className='mt-1'>
                        <Select
                            name="offices"
                            value={newSupplier.Office ?? null}
                            options={offices
                                ? offices.map(o => ({ label: o.name, value: o._id.$oid }))
                                : []}
                            styles={styleSelect}
                            classNamePrefix="select"
                            onChange={(choice) => setNewSupplier({ ...newSupplier, Office: choice })}
                            placeholder={data.t.read("invoice.office")} />
                    </div>}
                <div className='mb-2'>
                    <input placeholder={data.t.read("supplier_address")} className='form-control' type="text" value={newSupplier.Address} onChange={e => setNewSupplier({ ...newSupplier, Address: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("country")} className='form-control' type="text" value={newSupplier.Country} onChange={e => setNewSupplier({ ...newSupplier, Country: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("state")} className='form-control' type="text" value={newSupplier.State} onChange={e => setNewSupplier({ ...newSupplier, State: e.target.value })} />
                </div>
                <div className='mb-2'>
                    <input placeholder={data.t.read("city")} className='form-control' type="text" value={newSupplier.City} onChange={e => setNewSupplier({ ...newSupplier, City: e.target.value })} />
                </div>
                <div className='mb-4'>
                    <input placeholder={data.t.read("zipcode") + " ex: X1X 1X1"} className='form-control' type="text" value={newSupplier.Zipcode} onChange={e => setNewSupplier({ ...newSupplier, Zipcode: e.target.value })} />
                </div>
                {data.config.type === "legal" && (
                    <>
                        <div className='mb-4'>
                            <h5>{data.t.read("supplier.tax_code")}</h5>
                            <select value={newSupplier.TaxCode} placeholder={data.t.read("supplier.tax_code")} className='form-control' onChange={e => setNewSupplier({ ...newSupplier, TaxCode: e.target.value })}>
                                <option value="N_IN">{data.t.read("supplier.choices.tax_code.n_in")}</option>
                                <option value="T_IN">{data.t.read("supplier.choices.tax_code.t_in")}</option>
                            </select>
                        </div>
                        <div className='mb-2'>
                            <h5>{data.t.read("supplier.is_billable")}?</h5>
                            <select value={newSupplier.IsBillable} placeholder={data.t.read("supplier.is_billable")} className='form-control' onChange={e => setNewSupplier({ ...newSupplier, IsBillable: e.target.value })}>
                                <option value="true">{data.t.read("supplier.choices.is_billable.true")}</option>
                                <option value="false">{data.t.read("supplier.choices.is_billable.false")}</option>
                            </select>
                        </div>
                    </>
                )}
        </Modal>

};
export default SupplierForm;