import {inject, observer} from "mobx-react";
import React, {useEffect, useRef, useState} from "react";
import Modal from "../components/modal";
import WorkflowBranchEdit from "../components/workflow-branch-edit";
import FilterInputField from "../components/filter-input-field";
import PaginationControls from "../components/pagination-controls";
import Checkbox from "../components/checkbox";
import Switch from "react-switch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faPencilAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {fetch} from "../utils/api-wrapper";
import WorkflowEditStepModal from "../components/workflow-edit-step-modal";

// Main Workflow component
const Workflow = ({ data }) => {
    // STATE VARIABLES
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [workflows, setWorkflows] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);

    // For editing a specific step and panel (trigger, conditions, or actions)
    const [selectedStep, setSelectedStep] = useState(null);

    // Modal controls
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const [showWorkflowDetails, setShowWorkflowDetails] = useState(false);
    const [showDeleteWorkflowsModal, setShowDeleteWorkflowsModal] = useState(false);

    // Delete selection state
    const [workflowsDeleteSelection, setWorkflowsDeleteSelection] = useState([]);

    // Pagination and filter state
    const [pageSize, setPageSize] = useState(20);
    const [filterValue, setFilterValue] = useState("");
    const [sorting, setSorting] = useState([{ id: "name", desc: false }]);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [page, setPage] = useState(1);

    const branchButtonRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        fetchWorkflows();
    }, []);

    useEffect(() => {
        setPage(1);
        fetchWorkflows();
    }, [pageSize, sorting, filterValue]);

    useEffect(() => {
        fetchWorkflows();
    }, [page]);

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800" : "0 !important",
        }),
    };

    // HELPERS: Assign unique step IDs and branch IDs recursively.
    const assignUniqueStepIds = (workflow) => {
        let counter = 0;
        function processBranches(branches) {
            if (!branches || !branches.length) return;
            branches.forEach((branch) => {
                if (Array.isArray(branch.steps)) {
                    branch.steps.forEach((step) => {
                        counter++;
                        step.id = counter;
                        // Process nested branches inside a step if present
                        if (Array.isArray(step.branches) && step.branches.length > 0) {
                            processBranches(step.branches);
                        }
                    });
                }
            });
        }
        processBranches(workflow.branches);
        return workflow;
    };

    function assignUniqueBranchIds(workflow) {
        let counter = 0;
        function processBranches(branches) {
            if (!branches || !branches.length) return;
            branches.forEach((branch) => {
                counter++;
                branch.id = counter;
                if (Array.isArray(branch.steps)) {
                    branch.steps.forEach((step) => {
                        if (Array.isArray(step.branches) && step.branches.length > 0) {
                            processBranches(step.branches);
                        }
                    });
                }
            });
        }
        processBranches(workflow.branches);
        return workflow;
    }

    // SCROLL HELPER: Center the container on the branch button
    const centerContainer = () => {
        if (containerRef.current && branchButtonRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const buttonRect = branchButtonRef.current.getBoundingClientRect();
            const offsetLeft = buttonRect.left - containerRect.left;
            const scrollAmount =
                containerRef.current.scrollLeft +
                offsetLeft +
                buttonRect.width * 3 -
                containerRect.width / 2;
            containerRef.current.scrollTo({
                left: scrollAmount,
                behavior: "smooth",
            });
        }
    };

    // API CALL: Fetch workflows with current filters and pagination.
    const fetchWorkflows = () => {
        const start = (page - 1) * pageSize;
        const filters = [];
        if (filterValue && filterValue.trim() !== "") {
            filters.push({ id: "search", value: filterValue.trim() });
        }
        const searchData = `?filters=${encodeURIComponent(
            JSON.stringify(filters ?? [])
        )}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}&start=${encodeURIComponent(
            start
        )}&page_size=${pageSize}`;
        fetch(`/workflows/${searchData}`, "GET", {},
            (response) => {
                let fetchedWorkflows = response.data.workflows

                fetchedWorkflows.sort((a, b) => {
                    return (a.priority ?? 1) - (b.priority ?? 1)
                });

                // Process each workflow by assigning unique IDs
                fetchedWorkflows = fetchedWorkflows.map((w) => {
                    w.saved = true;
                    return assignUniqueStepIds(assignUniqueBranchIds(w));
                });



                setWorkflows(fetchedWorkflows);
                setTotalRowCount(response.data.meta.totalRowCount);
                setLoading(false);
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_fetch"));
            }
        );
    };

    // API CALL: Create a new workflow
    const createWorkflow = () => {
        const formData = new FormData();
        formData.append("workflow", JSON.stringify(selectedWorkflow));
        fetch("/workflows/", "POST", formData,
            () => {
                setSelectedWorkflow(null);
                setSuccessMessage(data.t.read("created_succesfully"));
                fetchWorkflows();
            },
            () => {
                setErrorMessage(data.t.read("failed_to_create"));
                setLoading(false);
            }
        );
    };

    // API CALL: Update an existing workflow
    const updateWorkflow = (workflow = { ...selectedWorkflow }) => {
        const workflowToSave = { ...workflow };
        delete workflowToSave._id;
        const formData = new FormData();
        formData.append("workflow", JSON.stringify(workflowToSave));
        const workflowId = workflow._id?.$oid;
        setSaving(true);
        fetch(`/workflows/${workflowId}`, "PUT", formData,
            () => {
                fetchWorkflows();
                setSelectedWorkflow(selectedWorkflow !== null ? {...selectedWorkflow, saved: true} : null);
                setSuccessMessage(data.t.read("workflow_updated_successfully"));
                setSaving(false);
            },
            () => {
                setErrorMessage(data.t.read("failed_to_update_workflow"));
                setLoading(false);
                setSaving(false);
            }
        );
    };

    // SAVE HANDLER: Validate before saving workflow
    const handleSave = () => {
        if (validateWorkflow(selectedWorkflow) === false) {
            setErrorMessage(data.t.read("rule.some_fields_empty_warning"));
            return;
        }
        if (selectedWorkflow._id) {
            updateWorkflow();
        } else {
            createWorkflow();
        }
    };

    // RECURSIVE VALIDATION FUNCTIONS

    // Validate workflow-level fields and then recursively check branches.
    function validateWorkflow(workflow) {
        if (!workflow.name || workflow.name.trim() === "") return false;
        if (!workflow.description || workflow.description.trim() === "") return false;
        const trigger = workflow.trigger;
        if (!trigger) return false;
        if (!trigger.type || trigger.type.trim() === "") return false;
        if (!trigger.initial_state || trigger.initial_state.trim() === "") return false;
        if (!trigger.final_state || trigger.final_state.trim() === "") return false;
        if (!workflow.branches || !Array.isArray(workflow.branches) || workflow.branches.length === 0) return false;
        for (let branch of workflow.branches) {
            if (!validateBranch(branch)) return false;
        }
        return true;
    }

    // Validate each branch recursively.
    function validateBranch(branch) {
        if (!branch.steps || !Array.isArray(branch.steps) || branch.steps.length === 0) return false;
        for (let step of branch.steps) {
            // If step contains nested branches, validate them recursively.
            if (step.branches && Array.isArray(step.branches) && step.branches.length > 0) {
                for (let nestedBranch of step.branches) {
                    if (!validateBranch(nestedBranch)) return false;
                }
            } else if (!validateStep(step)) {
                return false;
            }
        }
        return true;
    }

    // Validate a step: it must have at least one condition and one action.
    function validateStep(step) {
        if (!step.conditions || !Array.isArray(step.conditions) || step.conditions.length === 0) return false;
        if (!step.actions || !Array.isArray(step.actions) || step.actions.length === 0) return false;
        for (let condition of step.conditions) {
            if (!validateCondition(condition)) return false;
        }
        for (let action of step.actions) {
            if (!validateAction(action)) return false;
        }
        return true;
    }

    // Validate a condition – requires nonempty type, operator (if needed), and value.
    function validateCondition(condition) {
        if (!condition.type || condition.type.trim() === "") return false;
        // For some types, operator is required.
        if (
            (condition.type === "item_description" ||
                condition.type === "item_cost_type" ||
                condition.type === "item_gl") &&
            (!condition.operator || condition.operator.trim() === "")
        ) {
            return false;
        }
        if (
            condition.value === undefined ||
            condition.value === null ||
            condition.value.toString().trim() === ""
        ) {
            return false;
        }
        return true;
    }

    // Validate an action – requires nonempty type and value.
    function validateAction(action) {
        if (!action.type || action.type.trim() === "") return false;
        if ( action.type !=="only_keep_total" && action.type != "achat_and_payment" &&
            (action.value === undefined ||
            action.value === null ||
            action.value.toString().trim() === "")
        ) {
            return false;
        }
        return true;
    }

    // API CALL: Delete selected workflows
    const deleteWorkflows = (deletedSelection = workflowsDeleteSelection) => {
        fetch("/workflows/", "DELETE", { workflow_ids: deletedSelection },
            () => {
                fetchWorkflows();
                setWorkflowsDeleteSelection([]);
                setShowDeleteWorkflowsModal(false);
                setSelectedWorkflow(null)
            },
            (error) => {
                setErrorMessage(data.t.read("failed_to_delete"));
                setLoading(false);
                setShowDeleteWorkflowsModal(false);
            }
        );
    };

    // Cancel delete modal
    const cancelDeleteWorkflowModal = () => {
        setShowDeleteWorkflowsModal(false);
        setWorkflowsDeleteSelection([]);
    };

    // Helpers for checkbox selection in the workflow list
    const isAllWorkflowsSelected = () => {
        if (!workflows || !workflows.length) return false;
        return workflows.every((w) => workflowsDeleteSelection.includes(w._id?.$oid));
    };

    const isWorkflowSelected = (workflow) => {
        return workflowsDeleteSelection.includes(workflow._id?.$oid);
    };

    const toggleAllWorkflowsSelected = () => {
        setWorkflowsDeleteSelection(
            isAllWorkflowsSelected()
                ? workflowsDeleteSelection.filter(
                    (id) => !workflows.some((w) => w._id?.$oid === id)
                )
                : [
                    ...workflowsDeleteSelection,
                    ...workflows
                        .map((w) => w._id?.$oid)
                        .filter((id) => !workflowsDeleteSelection.includes(id)),
                ]
        );
    };

    // Create a new workflow (with default values)
    const newWorkflow = () => {
        let new_workflow = {
            description: "New workflow",
            name: "Untitled Workflow",
            date_created: Date.now(),
            date_updated: Date.now(),
            active: true,
            priority: 1,
            saved: false,
            trigger: {
                type: "invoice_status_transitions",
                initial_state: "any",
                final_state: "completed",
            },
            branches: [
                {
                    id: "",
                    steps: [
                        {
                            "name":"",
                            "active": true,
                            type: "step",
                            conditions: [
                                {
                                    id: 1,
                                    type: "amount_greater",
                                    operator: "contain",
                                    value: 0,
                                },
                            ],
                            actions: [
                                {
                                    id: 1,
                                    type: "send_email",
                                    value: "",
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        setSelectedWorkflow({ ...new_workflow });
        // Optionally show details panel
        setShowWorkflowDetails(true);
        setSelectedStep(null);
    };

    // Update the workflow trigger object
    const updateTrigger = (property, value) => {
        const updatedTrigger = { ...selectedWorkflow.trigger, [property]: value };
        const updatedWorkflow = { ...selectedWorkflow, trigger: updatedTrigger, saved: false };
        setSelectedWorkflow(updatedWorkflow);
    };

    // RECURSIVE FUNCTIONS FOR REMOVING AND UPDATING STEPS AND BRANCHES
    const removeStepFromBranch = (branch, step_index) => {
        let workflow = { ...selectedWorkflow };
        let targetBranchId = branch.id;

        // Recursively search for the branch and remove the step at step_index
        function searchAndRemove(branches) {
            if (!branches) return false;
            for (let branch of branches) {
                if (branch.id === targetBranchId) {
                    branch.steps.splice(step_index, 1);
                    return true;
                }
                if (branch.steps && branch.steps.length > 0) {
                    for (let step of branch.steps) {
                        if (step.branches && step.branches.length > 0) {
                            if (searchAndRemove(step.branches)) return true;
                        }
                    }
                }
            }
            return false;
        }

        // Cleanup any empty nested branches
        function cleanUpBranches(branches) {
            if (!branches) return [];
            branches.forEach((branch) => {
                if (branch.steps && branch.steps.length > 0) {
                    branch.steps.forEach((step) => {
                        if (step.type === "branch") {
                            step.branches = cleanUpBranches(step.branches);
                        }
                    });
                    branch.steps = branch.steps.filter((step) => {
                        if (step.type === "branch" && step.branches.length === 0) return false;
                        return true;
                    });
                }
            });
            return branches.filter((branch) => branch.steps && branch.steps.length > 0);
        }

        searchAndRemove(workflow.branches);
        workflow.branches = cleanUpBranches(workflow.branches);
        workflow = assignUniqueBranchIds(assignUniqueStepIds(workflow));
        workflow.saved = false;
        setSelectedWorkflow(workflow);
    };

    // Remove branch recursively (similar structure as removeStepFromBranch)
    const removeBranch = (branch, index) => {
        let workflow = { ...selectedWorkflow };
        let targetBranchId = branch.id;

        function searchAndRemove(branches) {
            if (!branches) return false;
            for (let branch of branches) {
                if (branch.id === targetBranchId) {
                    branch.steps.forEach((step) => {
                        if (step.type === "branch") {
                            step.branches?.splice(index, 1);
                        }
                    });
                    return true;
                }
                if (branch.steps && branch.steps.length > 0) {
                    for (let step of branch.steps) {
                        if (step.branches && step.branches.length > 0) {
                            if (searchAndRemove(step.branches)) return true;
                        }
                    }
                }
            }
            return false;
        }
        function cleanUpBranches(branches) {
            if (!branches) return [];
            branches.forEach((branch) => {
                if (branch.steps && branch.steps.length > 0) {
                    branch.steps.forEach((step) => {
                        if (step.type === "branch") {
                            step.branches = cleanUpBranches(step.branches);
                        }
                    });
                    branch.steps = branch.steps.filter((step) => {
                        if (step.type === "branch" && step.branches.length === 0) return false;
                        return true;
                    });
                }
            });
            return branches.filter((branch) => branch.steps && branch.steps.length > 0);
        }

        if(branch === "root"){
            workflow.branches.splice(index, 1)
        }
        else{
            searchAndRemove(workflow.branches);
        }
        workflow.branches = cleanUpBranches(workflow.branches);
        workflow = assignUniqueBranchIds(assignUniqueStepIds(workflow));
        workflow.saved = false;
        setSelectedWorkflow(workflow);
    };

    // Save step modal: find and update the edited step in the workflow using recursion.
    const saveStepModal = (updatedStep = selectedStep) => {
        if (!selectedWorkflow) return;
        const newWorkflow = { ...selectedWorkflow };
        const found = findAndUpdateStep(newWorkflow.branches, updatedStep);
        if (found) {
            newWorkflow.saved = false;
            setSelectedWorkflow(newWorkflow);
        }
        setSelectedStep(null);
    };

    // Recursively search for a step (by id) and update it.
    const findAndUpdateStep = (branches, updatedStep) => {
        if (!branches) return false;
        for (let b = 0; b < branches.length; b++) {
            const branch = branches[b];
            if (!branch.steps) continue;
            for (let s = 0; s < branch.steps.length; s++) {
                let step = branch.steps[s];
                if (step.id === selectedStep.id) {
                    branch.steps[s] = updatedStep;
                    return true;
                }
                if (step.branches && step.branches.length > 0) {
                    if (findAndUpdateStep(step.branches, updatedStep)) return true;
                }
            }
        }
        return false;
    };

    // Append a new step to a given branch at a specified index
    const appendStepToBranch = (branch, step_index) => {
        // Clone workflow deeply to avoid mutation
        let workflow = JSON.parse(JSON.stringify(selectedWorkflow));
        let targetBranchId = branch.id;
        // Template for a new step
        const newStepTemplate = {
            "name":"",
            "active": true,
            type: "step",
            conditions: [
                {id: 1, type: "amount_greater", operator: "contain", value: 0},
            ],
            actions: [
                { id: 1, type: "send_email", value: "" },
            ],
        };

        function searchAndAppend(branches) {
            if (!branches) return false;
            for (let branch of branches) {
                if (branch.id === targetBranchId) {
                    branch.steps = branch.steps || [];
                    branch.steps.splice(step_index, 0, { ...newStepTemplate, rank: step_index + 1 });
                    return true;
                }
                if (branch.steps && branch.steps.length > 0) {
                    for (let step of branch.steps) {
                        if (step.branches && step.branches.length > 0) {
                            if (searchAndAppend(step.branches)) return true;
                        }
                    }
                }
            }
            return false;
        }

        searchAndAppend(workflow.branches);
        workflow = assignUniqueBranchIds(assignUniqueStepIds(workflow));
        setSelectedWorkflow(workflow);
    };

    // Append a new branch to a branch
    const appendBranchToBranch = (branch, branchType = "children") => {
        let workflow = { ...selectedWorkflow };
        let targetBranchId = branch.id;
        const newBranchTemplate = {
            type: "branch",
            branches: [
                {
                    steps: [
                        {
                            "name":"",
                            "active": true,
                            type: "step",
                            conditions: [
                                {id: 1, type: "amount_greater", operator: "contain", value: 0},
                            ],
                            actions: [
                                { id: 1, type: "send_email", value: "" },
                            ],
                        },
                    ],
                },
                {
                    steps: [
                        {
                            "name":"",
                            "active": true,
                            type: "step",
                            conditions: [
                                {id: 1, type: "amount_greater", operator: "contain", value: 0},
                            ],
                            actions: [
                                { id: 1, type: "send_email", value: "" },
                            ],
                        },
                    ],
                },
            ],
        };

        if (targetBranchId === 0) {
            workflow.branches.push({ ...newBranchTemplate.branches[0] });
            workflow = assignUniqueBranchIds(assignUniqueStepIds(workflow));
            setSelectedWorkflow(workflow);
            centerContainer();
            return;
        }

        function searchAndAppend(branches) {
            if (!branches) return false;
            for (let branch of branches) {
                if (branch.id === targetBranchId) {
                    if (branchType === "parallel") {
                        branch.steps.forEach((step) => {
                            if (step.type === "branch") {
                                step.branches?.push({ ...newBranchTemplate.branches[0] });
                            }
                        });
                    } else {
                        branch.steps.push({ ...newBranchTemplate });
                    }
                    return true;
                }
                if (branch.steps && branch.steps.length > 0) {
                    for (let step of branch.steps) {
                        if (step.branches && step.branches.length > 0) {
                            if (searchAndAppend(step.branches)) return true;
                        }
                    }
                }
            }
            return false;
        }

        searchAndAppend(workflow.branches);
        workflow = assignUniqueBranchIds(assignUniqueStepIds(workflow));
        setSelectedWorkflow(workflow);
    };

    // Cancel edits: if unsaved, show modal; otherwise, reset selection and refetch.
    const cancelEdits = () => {
        if (selectedWorkflow && selectedWorkflow.saved === false) {
            setShowDiscardChangesModal(true);
        } else {
            setSelectedWorkflow(null);
            setSelectedStep(null);
            fetchWorkflows();
        }
    };

    // Reordering steps for drag-and-drop
    const reorderList = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleStepDragEnd = (result) => {
        if (!result.destination) return;
        if (
            result.source.index === result.destination.index &&
            result.source.droppableId === result.destination.droppableId
        )
            return;
        let updatedWorkflow = { ...selectedWorkflow };
        updatedWorkflow.steps = reorderList(
            updatedWorkflow.steps,
            result.source.index,
            result.destination.index
        );
        updatedWorkflow.steps.forEach((step, index) => {
            step.rank = index + 1;
        });
        updatedWorkflow.saved = false;
        setSelectedWorkflow(updatedWorkflow);
    };

    // Pagination handlers
    const handlePageChange = (newPage) => setPage(newPage);
    const handleRowsPerPageChange = (rows) => {
        setPage(1);
        setPageSize(rows);
    };

    // Derived pagination values
    const indexOfFirstItem = (page - 1) * pageSize;
    const indexOfLastItem = indexOfFirstItem + workflows.length - 1;
    const totalPages = Math.ceil(totalRowCount / pageSize);
    const emptyRowsCount = Math.max(0, pageSize - workflows.length);
    const emptyRows = Array(emptyRowsCount).fill({});

    // RENDER: Main return block
    return (
        <div className="card rounded-lg pb-3">
            {/* If workflows exist, render the main view */}
            {workflows ? (
                <div>
                    {/* LIST VIEW: When no workflow is selected, show the table view */}
                    {!selectedWorkflow && (
                        <div>
                            <div className="workflow-page pl-4 pr-4">
                                <div className="d-flex pt-4 pl-4 pr-4 pb-4 align-items-center">
                                    <h2>Workflows</h2>
                                </div>
                                <div className="pagination-row">
                                    <FilterInputField
                                        filterValue={filterValue}
                                        setFilterValue={setFilterValue}
                                        placeholder={""}
                                    />
                                    <PaginationControls
                                        page={page}
                                        totalPages={totalPages}
                                        indexOfFirstItem={indexOfFirstItem}
                                        indexOfLastItem={indexOfLastItem}
                                        handlePageChange={handlePageChange}
                                        totalItems={totalRowCount}
                                        data={data}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        rowsPerPage={pageSize}
                                    />
                                </div>
                                <div className="d-flex justify-content-end align-items-center py-2">
                                    <div className="d-flex">
                                        <button
                                            disabled={workflowsDeleteSelection.length === 0}
                                            onClick={() => setShowDeleteWorkflowsModal(true)}
                                            type="button"
                                            className="btn btn-secondary btn-sm ml-2"
                                        >
                                            {data.t.read("delete") + " (" + workflowsDeleteSelection.length + ")"}
                                        </button>
                                        <button onClick={() => newWorkflow()} type="button" className="btn btn-primary btn-sm ml-2">
                                            {data.t.read("add")}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-2 card rounded-lg admin-table">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th>
                                                <Checkbox
                                                    disabled={!workflows || !workflows.length}
                                                    isChecked={isAllWorkflowsSelected()}
                                                    onChange={toggleAllWorkflowsSelected}
                                                />
                                            </th>
                                            <th className="col-4">{data.t.read("name")}</th>
                                            <th className="col-6">Description</th>
                                            <th className="text-center col-2">{data.t.read("active")}</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {workflows.map((workflow, i) => (
                                            <tr key={workflow._id?.$oid || i}>
                                                <td>
                                                    <Checkbox
                                                        isChecked={isWorkflowSelected(workflow)}
                                                        onChange={() =>
                                                            isWorkflowSelected(workflow)
                                                                ? setWorkflowsDeleteSelection(
                                                                    workflowsDeleteSelection.filter((x) => x !== workflow._id?.$oid)
                                                                )
                                                                : setWorkflowsDeleteSelection([...workflowsDeleteSelection, workflow._id?.$oid])
                                                        }
                                                    />
                                                </td>
                                                <td className="col-4" onClick={() => setSelectedWorkflow(workflow)}>
                                                    {workflow.name}
                                                </td>
                                                <td className="col-6" onClick={() => setSelectedWorkflow(workflow)}>
                                                    {workflow.description}
                                                </td>
                                                <td className="col-2">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Switch
                                                            className="switch mt-0"
                                                            checked={workflow.active}
                                                            width={70}
                                                            onColor="#F39800"
                                                            onHandleColor="#2693e6"
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            onChange={(active) => {
                                                                updateWorkflow({...workflow, active: active});
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td onClick={() => setSelectedWorkflow(workflow)}>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <FontAwesomeIcon className="mr-2" icon={faPencilAlt} type="button" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {emptyRows.map((_, idx) => (
                                            <tr key={`empty-${idx}`}>
                                                <td style={{ height: "50px" }} colSpan="7">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* DETAIL VIEW: Render selected workflow details */}
                    {selectedWorkflow && (
                        <div>
                            <div className="workflow-page workflow-container">
                                <div className="d-flex p-4 align-items-center justify-content-between" style={{ background: "white" }}>
                                    <div className="d-flex ml-4 align-items-center">
                                        <div>
                                            <FontAwesomeIcon
                                                className="mr-3"
                                                icon={faChevronCircleLeft}
                                                type="button"
                                                style={{ fontSize: "18px" }}
                                                onClick={() => cancelEdits()}
                                            />
                                        </div>
                                        <h2>{selectedWorkflow.name}</h2>
                                        <div className="d-flex align-items-center">
                                            <button type="submit" className="btn btn-secondary mx-4" onClick={() => setShowWorkflowDetails(true)}>
                                                {data.t.read("details")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex mx-2 justify-content-between align-items-end">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{
                                            setWorkflowsDeleteSelection([selectedWorkflow._id?.$oid])
                                            setShowDeleteWorkflowsModal(true)                                        }
                                        }>
                                            {data.t.read("rule.delete_rule")}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={selectedWorkflow.saved === true}
                                            className="btn btn-primary mx-3"
                                            onClick={handleSave}
                                        >
                                            {data.t.read("rule.save_rule")}
                                        </button>
                                    </div>
                                </div>
                                {/* Container for branches and steps */}
                                <div className="" style={{ height: "81vh", overflow: "auto", scrollbarWidth: "none" }} ref={containerRef}>
                                    <div className="h-100" style={{ minWidth: "100%", width: "fit-content" }}>
                                        <div className="mx-4 my-0 pt-4 pb-0 border-radius-30">
                                            {/* Button to add a new branch */}
                                            <div className="d-flex justify-content-center">
                                                <div
                                                    className="card shadow-sm border-radius-30 btn-light"
                                                    type="button"
                                                    onClick={() => appendBranchToBranch({ id: 0 })}
                                                >
                                                    <div className="card-body" ref={branchButtonRef}>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <FontAwesomeIcon icon={faPlus} />
                                                            <span className="ml-2">{data.t.read("branch")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-connector" />
                                            <div className="d-flex justify-content-center border-radius-30 h-100">
                                                {selectedWorkflow.branches.map((branch, index) => (
                                                    <div className="d-flex w-100 justify-content-center" key={index}>
                                                        <WorkflowBranchEdit
                                                            branch={branch}
                                                            data={data}
                                                            onStepClicked={setSelectedStep}
                                                            addStep={appendStepToBranch}
                                                            addBranch={appendBranchToBranch}
                                                            deleteStep={removeStepFromBranch}
                                                            deleteBranch={removeBranch}
                                                            branchPosition={
                                                                selectedWorkflow.branches.length === 1
                                                                    ? "single"
                                                                    : index === 0
                                                                        ? "start"
                                                                        : index === selectedWorkflow.branches.length - 1
                                                                            ? "end"
                                                                            : "middle"
                                                            }
                                                            branch_index={index}
                                                            branchToDelete={"root"}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Step editing modal */}
                    {selectedStep != null && (
                        <Modal title={"Edit Step"} width={"800px"}>
                            <WorkflowEditStepModal
                                selectedStep={selectedStep}
                                data={data}
                                setSelectedStep={setSelectedStep}
                                onSubmit={() => saveStepModal()}
                                onCancel={() => setSelectedStep(null)}
                            />
                        </Modal>
                    )}
                    {/* Workflow details modal */}
                    {showWorkflowDetails && (
                        <Modal
                            title={"Workflow Details"}
                            width={"600px"}
                            okText={"OK"}
                            okAction={() => setShowWorkflowDetails(false)}
                            cancelText={"Cancel"}
                            cancelAction={() => setShowWorkflowDetails(false)}
                        >
                            <div className="card-body">
                                <div className="row mb-4">
                                    <label className="col-3">{data.t.read("rule.name")}:</label>
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            onFocus={(e) => e.target.select()}
                                            required
                                            className="form-control"
                                            placeholder={data.t.read("rule.name")}
                                            value={selectedWorkflow.name}
                                            onChange={(e) =>
                                                setSelectedWorkflow({
                                                    ...selectedWorkflow,
                                                    name: e.target.value,
                                                    saved: false,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-3">{data.t.read("rule.status")}:</label>
                                    <div className="col-8">
                                        <Switch
                                            className="switch mt-0"
                                            checked={selectedWorkflow.active}
                                            width={70}
                                            onColor="#F39800"
                                            onHandleColor="#2693e6"
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            onChange={(active) =>
                                                setSelectedWorkflow({
                                                    ...selectedWorkflow,
                                                    active: active,
                                                    saved: false,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-3">{data.t.read("rule.priority")}:</label>
                                    <div className="col-8">
                                        <input
                                            type="number"
                                            onFocus={(e) => e.target.select()}
                                            required
                                            className="form-control"
                                            placeholder={data.t.read("rule.name")}
                                            value={selectedWorkflow.priority ?? 1}
                                            onChange={(e) =>
                                                setSelectedWorkflow({
                                                    ...selectedWorkflow,
                                                    priority: parseInt(e.target.value),
                                                    saved: false,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-3">Description:</label>
                                    <div className="col-8">
                                        <textarea required className="form-control" placeholder="Description"
                                                  value={selectedWorkflow.description}
                                                  onChange={(e) => setSelectedWorkflow({
                                                      ...selectedWorkflow,
                                                      description: e.target.value,
                                                      saved: false,
                                                  })}/>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )}
                    {/* Error Modal */}
                    {errorMessage && (
                        <Modal title={data.t.read("attention")} okText={"OK"} okAction={() => setErrorMessage(null)}>
                            <p style={{whiteSpace: "pre-line"}} className="text-danger">
                                {errorMessage}
                            </p>
                        </Modal>
                    )}
                    {/* Success Modal */}
                    {successMessage && (
                        <Modal title={data.t.read("attention")} okText={"OK"} okAction={() => setSuccessMessage(null)}>
                            {successMessage}
                        </Modal>
                    )}
                    {/* Discard changes Modal */}
                    {showDiscardChangesModal && (
                        <Modal
                            title={data.t.read("rule.discard_changes")}
                            okAction={() => {
                                setSelectedWorkflow(null);
                                fetchWorkflows();
                                setShowDiscardChangesModal(false);
                            }}
                            cancelAction={() => setShowDiscardChangesModal(false)}
                            okText={"OK"}
                            cancelText={data.t.read("cancel")}
                        >
                            <p className="text-danger">{data.t.read("rule.unsaved_changes_warning")}</p>
                        </Modal>
                    )}
                    {/* Delete workflows Modal */}
                    {showDeleteWorkflowsModal && (
                        <Modal
                            title={data.t.read("office_deletion")}
                            okAction={deleteWorkflows}
                            cancelAction={cancelDeleteWorkflowModal}
                            okText={data.t.read("delete")}
                            cancelText={data.t.read("cancel")}
                        >
                            <p className="text-danger">{data.t.read("delete")}?</p>
                        </Modal>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default inject("data")(observer(Workflow));