import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import Invoice from '../components/invoice';
import Modal from '../components/modal';
import FormSelectUser from '../components/form-select-user';
import Checkbox from '../components/checkbox';
import Utils from '../utils/utils';
import axios from "axios";

import iconSpeaker from '../assets/img/icon-speaker.svg';
import iconHelp from '../assets/img/icon-help.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCog, faSync } from '@fortawesome/free-solid-svg-icons';
import {downloadFile, downloadFiles} from '../utils/file-manager';

import { fetch } from '../utils/api-wrapper';

export default inject("data")(observer(class Archives extends Component {

    constructor(props) {
        super(props);

        let colsToDisplay = ["created_at", "updated_at", "json.ListSupplier[0].Number", "json.ListSupplier[0].Name", "json.InvoiceNo", "status"];

        if (localStorage["colsToDisplayArchives"] != null) {
            colsToDisplay = JSON.parse(localStorage["colsToDisplayArchives"]);
        }

        this.exportFormat = "acomba";
        this.source =  axios.CancelToken.source();


        this.state = {
            invoices: [],
            invoiceSelection : [],
            users: [],
            searchStr: "",
            searchUser: null,
            searchDateMin: "",
            searchDateMax: "",
            searchCreatedDateMin: "",
            searchCreatedDateMax: "",
            validatedSearchStr: "",
            validatedSearchUser: null,
            validatedSearchDateMin: "",
            validatedSearchDateMax: "",
            validatedSearchCreatedDateMin: "",
            validatedSearchCreatedDateMax: "",
            currentInvoice: null,
            total: "",
            processingTotal: "",
            monthlyTotals: [],
            actionType: null,
            showConnectAsPrompt: false,
            connectAs: "Clerk",
            showAdvancedSearch: false,
            showTableConfig: false,
            columnsToDisplay: colsToDisplay,
            columnsToDisplayTmp: [],
            pageNumber: 1,
            pageCount: 1,
            exporting : new Set(),
            allInvoices: []
        }

        this.colsToNotFilter = ["created_at", "updated_at", "billing_data", "total"];
        this.currentStatus = null;
        this.sortedColumn = { "name": "", "sortAsc": true };

        this.availableColumns = [
            { "name": "created_at", "evaluate": inv => Intl.DateTimeFormat(`${this.props.data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(inv.created_at)), "displayName": "created_at" },
            { "name": "updated_at", "evaluate": inv => Intl.DateTimeFormat(`${this.props.data.t.lang}-US`,{year: 'numeric',  month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,  }).format(new Date(inv.updated_at)), "displayName": "updated_at" },
            { "name": "json.InvoiceDate", "evaluate": inv => inv.json ? inv.json.InvoiceDate : "", "displayName": "billing_date" },
            { "name": "json.DueDate", "evaluate": inv => inv.json && inv.json.DueDate ? inv.json.DueDate : "", "displayName": "due_date" },
            { "name": "json.ListSupplier[0].Number", "evaluate": inv => inv.json && inv.json.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Number : "", "displayName": "supplier_number" },
            { "name": "json.ListSupplier[0].Name", "evaluate": inv => inv.json && inv.json.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Name : "", "displayName": "supplier_name" },
            { "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : "", "displayName": "invoice_number" },
            { "name": "json.Currency", "evaluate": inv => inv.json ? inv.json.Currency : "", "displayName": "currency" },
            { "name": "status", "evaluate": inv => inv.status, "displayName": "status" },
            { "name": "json.Total", "evaluate": inv => inv.json && inv.json.Total ? "$" + inv.json.Total.toFixed(2) : "", "displayName": "total" },
            { "name": "json.GLUnit", "evaluate": inv => inv.json && inv.json.GLUnit ? inv.json.GLUnit : "", "displayName": "unit" },
            { "name": "username", "evaluate": inv => inv.username ? inv.username : "", "displayName": "username" },
            { "name": "approver", "evaluate": inv => inv.json && inv.json.approver ? inv.json.approver.first_name + " " + inv.json.approver.last_name  : "", "displayName": "approver" },
        ]
    }

    searchUnit(event) {
        let valueToSearch = event.target.value.toLowerCase();
        let invoicesBody = document.querySelector(".invoices tbody");
        let invoices = invoicesBody.children;
        let indexUnit = this.state.columnsToDisplay.indexOf("json.GLUnit") + 2

        if (invoices.length === 0) return;

        for (var i = 0; i < invoices.length; i++) {
            let el1 = invoices[i].querySelector(`td:nth-child(${indexUnit})`).textContent.toLowerCase();

            if (el1.includes(valueToSearch))
                invoices[i].style.display = "table-row";
            else
                invoices[i].style.display = "none";
        }
    }

    getNestedObject(obj, field) {
        let lst = field.replace("[", ".").replace("]", "").split(".");
        for (let i = 0; i < lst.length; i++) {
            
            if(obj === undefined || obj === null || obj.length === 0)
                break;

            if(typeof field[i] === "number")
                field[i] = Number(field[i]);
                
            obj = obj[lst[i]];
        }
        return obj;
    }

    sortInvoices(column) {
        let invoices = this.state.allInvoices;
        let sortedInvoices = [];

        if (this.sortedColumn.name === column) {
            this.sortedColumn.sortAsc = !this.sortedColumn.sortAsc;
        }
        else {
            this.sortedColumn.name = column;
            this.sortedColumn.sortAsc = true;
        }

        for (let i = 0; i < invoices.length; i++) {
            let val = this.getNestedObject(invoices[i], column);
            if (column === "json.Total") val = Number(val);

            for (let j = i + 1; j < invoices.length; j++) {
                let el2 = this.getNestedObject(invoices[j], column);
                if (column === "json.Total") el2 = Number(el2);

                if ((val <= el2 && !this.sortedColumn.sortAsc) || (val >= el2 && this.sortedColumn.sortAsc)) {
                    let tmp = invoices[i];
                    invoices[i] = invoices[j];
                    invoices[j] = tmp;
                    val = el2;
                }
            }
            sortedInvoices.push(invoices[i]);
        }

        this.setState({
            allInvoices: sortedInvoices
        }, () => {
            this.displayInvoices();
        })
    }


    refreshInvoices(page = null) {
        if (this.state.currentInvoice === null) {
            this.currentStatus = this.props.data.selectedStatus;

            let searchData = "?";

            if (this.state.validatedSearchStr.trim().length > 0) {
                searchData += "str=" + encodeURIComponent(this.state.validatedSearchStr) + "&";
            }

            if (this.state.validatedSearchDateMin.trim().length > 0) {
                searchData += "date-min=" + encodeURIComponent(this.state.validatedSearchDateMin) + "&";
            }

            if (this.state.validatedSearchDateMax.trim().length > 0) {
                searchData += "date-max=" + encodeURIComponent(this.state.validatedSearchDateMax) + "&";
            }

            if (this.state.validatedSearchCreatedDateMin.trim().length > 0) {
                searchData += "created-date-min=" + encodeURIComponent(this.state.validatedSearchCreatedDateMin) + "&";
            }

            if (this.state.validatedSearchCreatedDateMax.trim().length > 0) {
                searchData += "created-date-max=" + encodeURIComponent(this.state.validatedSearchCreatedDateMax) + "&";
            }

            if (this.state.validatedSearchUser != null && this.state.validatedSearchUser.username != null && this.state.validatedSearchUser.username.trim().length > 0) {
                searchData += "username=" + encodeURIComponent(this.state.validatedSearchUser.username) + "&";
            }

            fetch(  "/archives/list/" + this.currentStatus + searchData, "GET", {},
                response => {
                    if (response.data.invoices == null) return;

                    let invoices = response.data.invoices.sort(function (a, b) { return (a.created_at < b.created_at ? 1 : -1); });
                    this.setState({
                        pageCount: Math.floor(response.data.total / 100) + 1
                    });
                    this.setState({
                        allInvoices: invoices
                    }, () => {
                        this.displayInvoices();

                        fetch(  "/invoices/stats", "GET", {},
                            response => {
                                this.setState({
                                    total: response.data.total,
                                    processingTotal: response.data.process_total,
                                    monthlyTotals: response.data.monthly_totals
                                })
                            })
                    })
                }, 
                null, false, this.source.token)
            }
    }

    componentDidUpdate() {
        
        if(this.props.data.config?.invoice_exporter?.export_format){
            this.exportFormat = this.props.data.config.invoice_exporter.export_format
        }    

        if (this.currentStatus !== this.props.data.selectedStatus) {
            this.currentStatus = this.props.data.selectedStatus;

            this.setState({
                invoices: [],
                invoiceSelection: [],
                searchStr: "",
                validatedSearchStr: ""
            }, () => {
                this.refreshInvoices(this.props.data.selectedStatus);
            });
        }

    }

    componentDidMount() {
        this.currentStatus = this.props.data.selectedStatus;
        this.refreshInvoices();

        fetch(  "/client/usersDetail", "GET", {},
            response => {
                this.setState({
                    users: response.data
                })
            })
    }

    selectInvoice(invoice) {
        if(invoice.type === "XPENSE"){
            this.props.navigate(`/expenses_account/${invoice.id_expense_account}`);
            return;
        }
        if(invoice.type === "personal-card"){
            this.props.navigate(`/c_card/statement/${invoice.c_card_statement_id}`);
            return;
        }
        this.setState({
            currentInvoice: invoice._id
        })
    }

    /**
     * This method export the selected invoices in the selected format.
     * TODO : We should not do a querySelectorAll everytime we click to export invoices.
     * @param {str} format 
     */
    exportSelected(format) {        
        this.setState({ exporting: new Set([...this.state.exporting, format]) });
        const lst = [...this.state.invoiceSelection.flatMap(x => x._id)]
        
        if(![ "acomba", 'pdf', 'elite_xml', 'json'].includes(format))
        {
            fetch(  "/invoices/" + format + "/", "GET", { "ids": lst },
            response => 
            {  
                this.refreshInvoices();
                if(response.data.inserted !== true)
                    this.setState({
                        errorMessage: response.data.error.errorCode + ': ' + response.data.error.errorMessage.value,
                        exporting: new Set()
                    })
                this.setState({
                    successMessage: response.data.message,
                    exporting: new Set(),
                    invoiceSelection: []
                })
            },
            error => {
                this.setState({
                    errorMessage: 'erreur inattendue',
                    exporting: new Set()
                })
            }, false, this.source.token)
            return;
        }

        if (lst.length > 1) 
            downloadFiles( lst, format, () => {this.setState({exporting: new Set(),invoiceSelection: []}); this.refreshInvoices();} );
        else 
            downloadFile( lst[0], format, () => {this.setState({exporting: new Set(),invoiceSelection: []}); this.refreshInvoices();} );
    }

    containNonValidatedInvoice = () => {
        return this.state.invoiceSelection.find(x => x.status !== 'Validated') !== undefined;
    }

    closeInvoicePrompt(mustRefresh) {
        this.setState({
            currentInvoice: null,
            invoices: mustRefresh ? [] : this.state.invoices,
        }, () => {
            if (mustRefresh) {
                this.refreshInvoices()
            }
        })
    }

    checkSearch(e) {
        if (e.key === "Enter") {
            if (this.state.searchStr.trim().length > 0) {
                this.currentStatus = "All";
                this.props.data.selectedStatus = "All";
            }

            this.setState({
                validatedSearchStr: this.state.searchStr
            }, () => {
                this.refreshInvoices();
            })
        }
    }

    processAction() {
        const invoices = this.state.invoiceSelection
        if (this.state.actionType === "delete") {
            let count = invoices.length;
            invoices.forEach(inv => {
                    fetch(  "/invoices/" + inv._id, "DELETE", {},
                        response => {
                            count--;

                            if (count === 0) {
                                this.setState({ actionType: null, invoiceSelection: [] }, () => this.refreshInvoices());
                            }
                        },
                        response => {
                            count--;

                            if (count === 0) {
                                this.setState({ actionType: null, invoiceSelection: [] }, () => this.refreshInvoices());
                            }
                        },
                        false, this.source.token
                    )
            });
        }
    }

    promptConnectAs() {
        this.setState({
            showConnectAsPrompt: true
        })
    }

    confirmConnectAs() {
        let formData = new FormData();
        formData.append("role", this.state.connectAs);

        fetch(  "/auth/connect-as-role", "POST", formData,
            response => {
                window.localStorage.setItem("jwt", response.data.token);
                window.location.reload();
            },
            response => {
                this.setState({
                    showConnectAsPrompt: false
                })
            })
    }

    toggleAdvancedSearch() {
        this.setState({
            showAdvancedSearch: !this.state.showAdvancedSearch
        })
    }

    isAllInvoicesSelected = () => {
        return this.state.invoiceSelection.length > 1 && this.state.invoiceSelection.length === this.state.invoices.length;
    }

    toggleAllInvoicesSelected = () => {
        this.state.invoiceSelection.length === this.state.invoices.length ? this.setState({invoiceSelection: []}) : this.setState( {invoiceSelection: [...this.state.invoices]});
    }

    isInvoiceSelected = (inv) => {
        return this.state.invoiceSelection.find(x => x._id === inv._id) !== undefined;
    }

    shoulDisableActions = () => this.state.transferring || this.state.exporting.size || !this.state.invoiceSelection.length || this.state.actionType === 'delete';


    processAdvancedSearch() {
        this.setState({
            showAdvancedSearch: false,
            validatedSearchUser: this.state.searchUser,
            validatedSearchDateMin: this.state.searchDateMin,
            validatedSearchDateMax: this.state.searchDateMax,
            validatedSearchCreatedDateMin: this.state.searchCreatedDateMin,
            validatedSearchCreatedDateMax: this.state.searchCreatedDateMax,
        }, () => {
            this.refreshInvoices();
        })
    }

    resetAdvancedSearch() {
        this.setState({
            searchUser: null,
            searchDateMin: "",
            searchDateMax: "",
            searchCreatedDateMin: "",
            searchCreatedDateMax: "",
            validatedSearchUser: null,
            validatedSearchDateMin: "",
            validatedSearchDateMax: "",
            validatedSearchCreatedDateMin: "",
            validatedSearchCreatedDateMax: "",
        }, () => {
            this.refreshInvoices();
        })
    }

    showTableConfig() {
        let columnsToDisplayTmp = [...this.state.columnsToDisplay]
        this.setState({
            columnsToDisplayTmp: columnsToDisplayTmp,
            showTableConfig: true
        });
    }

    updateColumnToDisplay(name) {
        let idx = this.state.columnsToDisplayTmp.indexOf(name);

        if (idx === -1) {
            let cols = this.state.columnsToDisplayTmp;
            cols.push(name);

            this.setState({
                columnsToDisplayTmp: cols
            })
        }
        else {
            let cols = this.state.columnsToDisplayTmp;
            cols.splice(idx, 1);

            this.setState({
                columnsToDisplayTmp: cols
            })
        }
    }

    saveColumnsToDisplay() {
        localStorage["colsToDisplayArchives"] = JSON.stringify(this.state.columnsToDisplayTmp);

        this.setState({
            columnsToDisplay: this.state.columnsToDisplayTmp,
            showTableConfig: false
        })
    }

    getColumnValue(invoice, column) {
        let value = "";

        try {
            value = column.evaluate(invoice)
        }
        catch (e) {
        }

        return value;
    }

    onKeyDownFunction(e) {
        if (e.key === "Escape") {
            this.setState({
                currentInvoice: null
            });
        }
    }

    handlePageChange(page) {
        if (page > this.state.pageCount || page < 1) {
            return;
        }
        let updatedPage = page;
        this.setState({
            pageNumber: updatedPage,
            invoices: []
        }, () => {
            this.displayInvoices();
        });
    }

    displayInvoices() {
        let invoices = [];

        for (let i = (this.state.pageNumber - 1) * 100; i < this.state.pageNumber * 100 && i < this.state.allInvoices.length; i++) {
            invoices.push(this.state.allInvoices[i]);
        }

        this.setState({
            invoices: invoices
        });
    }

    renderPagination() {
        let toRender = [];
        for (let i = 0; i < this.state.pageCount; i++) {
            if ((i === 0 || i + 1 === this.state.pageCount) || (i + 1 >= this.state.pageNumber - 3 && i + 1 <= this.state.pageNumber + 3)) {
                if ((i === 0 || i + 1 === this.state.pageCount) || (i + 1 >= this.state.pageNumber - 2 && i + 1 <= this.state.pageNumber + 2)) {
                    let classes = "page-item";
                    if (i + 1 === this.state.pageNumber)
                        classes += " active";
                    toRender.push(<li key={'page-'+i} className={classes}><p className="page-link" onClick={() => this.handlePageChange(i + 1)}>{i + 1}</p></li>);
                } else {
                    toRender.push(<li key={'page-'+i} className="page-item disabled"><p className="page-link">...</p></li>);
                }
            }
        }
        return toRender.map((e) => {
            return e;
        });
    }

    render() {
        let availableRoles = [];

        if (this.props.data.role != null) {
            if (this.props.data.role === "SuperAdmin") {
                availableRoles = ["Admin", "Manager", "Clerk"];
            }
            else if (this.props.data.role === "Admin") {
                availableRoles = ["Manager", "Clerk"];
            }
            else if (this.props.data.role === "Manager") {
                availableRoles = ["Clerk"];
            }
        }

        return <div tabIndex={-10} onKeyDown={e => this.onKeyDownFunction(e)} style={{ outline: "none" }}>
            {
                this.state.actionType != null ?
                    <Modal title={this.props.data.t.read("confirm")} cancelAction={() => this.setState({ actionType: null })} okAction={() => this.processAction()} okText={this.props.data.t.read(this.state.actionType)} cancelText={this.props.data.t.read("cancel")}>
                        {this.props.data.t.read("modal_" + this.state.actionType)}
                    </Modal>
                    :
                    null
            }
            {
                this.state.showTableConfig ?
                    <Modal title={this.props.data.t.read("table_config_title")} cancelAction={() => this.setState({ showTableConfig: false })} okAction={() => this.saveColumnsToDisplay()} okText={this.props.data.t.read("save")} cancelText={this.props.data.t.read("cancel")}>
                        {this.props.data.t.read("table_config_content")}
                        <div className="mt-4">
                            {
                                this.availableColumns
                                .filter(col => col.name !== "approver" || Utils.userHasPageAccess(this.props.data, "approval"))
                                .map(col => {
                                    return <div key={col.name}>
                                        <label>
                                            <input type="checkbox" onChange={() => this.updateColumnToDisplay(col.name)} checked={this.state.columnsToDisplayTmp.indexOf(col.name) !== -1 ? true : false} /> {this.props.data.t.read("invoice." + col.displayName)}
                                        </label>
                                    </div>
                                })
                            }
                        </div>
                    </Modal>
                    :
                    null
            }
            {
                this.state.showConnectAsPrompt ?
                    <Modal title={this.props.data.t.read("connect_as")} cancelAction={() => this.setState({ showConnectAsPrompt: false })} okAction={() => this.confirmConnectAs()} okText={this.props.data.t.read("connect")} cancelText={this.props.data.t.read("cancel")}>
                        {
                            availableRoles.map(r => {
                                return <label className="d-block" key={"modal-role-" + r}>
                                    <input type="radio" name="connect_as" checked={this.state.connectAs === r} onChange={() => this.setState({ connectAs: r })} />
                                    {" " + this.props.data.t.read("role_" + r.toLowerCase()) + " "}
                                </label>
                            })
                        }
                    </Modal>
                    :
                    null
            }
            <div>
                <div className="d-flex justify-content-between mb-4">
                    <div className="flex-start">
                        <input type="text" className="form-control search-field" placeholder={this.props.data.t.read("search_by")} value={this.state.searchStr} onChange={e => this.setState({ searchStr: e.target.value })} onKeyUp={e => this.checkSearch(e)} />
                        <div>
                            <button className="btn btn-sm btn-link btn-fade-over" onClick={() => this.toggleAdvancedSearch()}>
                                {this.props.data.t.read("advanced_search")} <FontAwesomeIcon className="text-primary" icon={faAngleDown} />
                            </button>
                        </div>
                        <div className="position-relative">
                            {
                                this.state.showAdvancedSearch ?
                                    <div className="advanced-search-section">
                                        {
                                            this.state.users.length > 0 ?
                                                <div className="search-line">
                                                    <div>{this.props.data.t.read("invoice.user")} :</div>
                                                    <div>
                                                        <FormSelectUser chosenOption={this.state.searchUser} options={this.state.users.sort((a,b) => a["first_name"] + a["last_name"] > b["first_name"] +b["last_name"] ? 1 :-1)} optionChanged={option => { this.setState({ searchUser: option }) }} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className="search-line">
                                            <div>{this.props.data.t.read("invoice.billing_date")} :</div>
                                            <div>
                                                <input type="date" className="form-control search-input-field" value={this.state.searchDateMin} onChange={e => this.setState({ searchDateMin: e.target.value })} />
                                            </div>
                                            <div>
                                                -
                                            </div>
                                            <div>
                                                <input type="date" className="form-control search-input-field" value={this.state.searchDateMax} onChange={e => this.setState({ searchDateMax: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="search-line">
                                            <div>{this.props.data.t.read("invoice.inserted_date")} :</div>
                                            <div>
                                                <input type="date" className="form-control search-input-field" value={this.state.searchCreatedDateMin} onChange={e => this.setState({ searchCreatedDateMin: e.target.value })} />
                                            </div>
                                            <div>
                                                -
                                            </div>
                                            <div>
                                                <input type="date" className="form-control search-input-field" value={this.state.searchCreatedDateMax} onChange={e => this.setState({ searchCreatedDateMax: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <button className="btn btn-sm btn-link btn-fade-over" onClick={() => this.resetAdvancedSearch()}>
                                                {this.props.data.t.read("reset")}
                                            </button>
                                            <button className="btn btn-sm btn-primary" onClick={() => this.processAdvancedSearch()}>
                                                {this.props.data.t.read("search")}
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="flex-end">
                        <img src={iconSpeaker} alt="Notifications" className="faded mr-2" />
                        <img src={iconHelp} alt="Help" className="faded" />
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                    <div className="flex-3">

                    </div>
                    <div className="flex-5 ml-3 pt-3 pl-3 pr-0 d-flex flex-column">
                        <div className="text-right mt-auto pt-2">                        
                            <button className="btn btn-primary btn-sm mr-2" disabled = {this.containNonValidatedInvoice() || this.shoulDisableActions()} onClick={() => this.exportSelected("json")} >{this.props.data.t.read("export_json")} {this.state.exporting.has('json') && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}</button>
                            <button className="btn btn-primary btn-sm mr-2" disabled = {this.containNonValidatedInvoice() || this.shoulDisableActions()} onClick={() => this.exportSelected("elite_xml")} >{this.props.data.t.read("export_elite_xml")} {this.state.exporting.has('elite') && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}</button>
                            <button className="btn btn-primary btn-sm mr-2" disabled = {this.containNonValidatedInvoice() || this.shoulDisableActions()} onClick={() => this.exportSelected("pdf")} >{this.props.data.t.read("export_pdf")} {this.state.exporting.has('pdf') && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}</button>
                            <button className="btn btn-primary btn-sm mr-2" disabled = {this.containNonValidatedInvoice() || this.shoulDisableActions()} onClick={() => this.exportSelected(this.exportFormat)} >{this.props.data.t.read("finalize")} {this.state.exporting.has(this.exportFormat) && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}</button>
                            <button className="btn btn-secondary btn-sm"  disabled={this.shoulDisableActions()} onClick={() => this.setState({actionType: 'delete'})} >{this.props.data.t.read("delete")}</button>
                        </div>
                    </div>
                </div>

                <div className={"invoices status-" + this.props.data.selectedStatus}>
                    <table className="table bg-white table-hover">
                        <thead>
                            <tr>
                                <th>
                                     <Checkbox disabled={!this.state.invoices?.length} isChecked={this.isAllInvoicesSelected()} onChange={this.toggleAllInvoicesSelected} />
                                </th>
                                {
                                    this.state.columnsToDisplay
                                    .filter(col => col !== "approver" || Utils.userHasPageAccess(this.props.data, "approval"))
                                    .map(col => {
                                        let column = this.availableColumns.find(c => c.name === col);

                                        return <th key={"header-" + column.displayName}>
                                            <div onClick={() => this.sortInvoices(column.name)}>{this.props.data.t.read("invoice." + column.displayName)}</div>
                                        </th>
                                    })
                                }
                                <th style={{ width: "30px", textAlign: "center" }}>
                                    <FontAwesomeIcon className="text-secondary" icon={faCog} onClick={() => this.showTableConfig()} style={{ cursor: "pointer" }} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.invoices.map(invoice => {
                                    return <tr key={invoice._id} onClick={() => this.selectInvoice(invoice)}>
                                        <td onClick={e => e.stopPropagation()}>
                                            <Checkbox isChecked={this.isInvoiceSelected(invoice)} onChange={e => this.isInvoiceSelected(invoice) ? this.setState({ invoiceSelection: [...this.state.invoiceSelection.filter(x => x._id != invoice._id)]}) : this.setState({invoiceSelection: [...this.state.invoiceSelection, invoice]})} />
                                        </td>
                                        {
                                            this.state.columnsToDisplay.map(col => {
                                                let column = null;

                                                this.availableColumns.forEach(c => {
                                                    if (c.name === col) {
                                                        column = c;
                                                    }
                                                })
                                                return <td key={"body-" + column.displayName} className={column.name === "status" ? "invoice-row-status-" + invoice.status.toLowerCase() : ""}>
                                                    {this.getColumnValue(invoice, column) + ""}
                                                </td>
                                            })
                                        }
                                        <td></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                this.state.currentInvoice != null ?
                    <div className="overlay" onClick={() => this.closeInvoicePrompt(false)}>
                        <div className="overlay-content">
                            <div className="invoice-container" onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                                <Invoice id={this.state.currentInvoice} close={mustRefresh => this.closeInvoicePrompt(mustRefresh)} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="d-flex justify-content-center">
                        <ul className="pagination">
                            <li key={'previous_page'} className={this.state.pageNumber <= 1 ? "page-item disabled" : "page-item"}><p className="page-link" onClick={() => this.handlePageChange(this.state.pageNumber - 1)}>{this.props.data.t.read("previous_page")}</p></li>
                            {this.renderPagination()}
                            <li key={'next_page'} className={this.state.pageNumber >= this.state.pageCount ? "page-item disabled" : "page-item"}><p className="page-link" onClick={() => this.handlePageChange(this.state.pageNumber + 1)}>{this.props.data.t.read("next_page")}</p></li>
                        </ul>
                    </div>
            }
        </div>
    }
}));