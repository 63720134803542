import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { Children } from 'react';
import { useMediaQuery } from 'react-responsive';


export default function CustomResizable({type, column, border, children}) {
   let invoiceResized, columnResized, size;

   const isMobile = useMediaQuery({ maxWidth: 768 });


   if (type === "Invoice" && localStorage["InvoiceResized"] !== undefined && localStorage["InvoiceResized"] !== null) {
      invoiceResized = JSON.parse(localStorage["InvoiceResized"]);
   }
   else if(type === "Column" && localStorage["ColumnResized-"+column] !== undefined && localStorage["ColumnResized-"+column] !== null){
      columnResized = JSON.parse(localStorage["ColumnResized-"+column]);
   }
   
   
   if(type === "Invoice")
   {
      size = isMobile ? { width: "100%", height: "100vh"} : { width: invoiceResized ? invoiceResized.width : 896, height: invoiceResized ? invoiceResized.height : "100vh"};
   }
   else {
      size = { width: columnResized ? columnResized.width : 150, height: columnResized ? columnResized.height : "5vh"};
   }

   const [state, setState] = useState({ width: size.width, height: size.height});

   return (
      <Resizable
         style={{ "borderTop": border && border.borderTop ? border.borderTop : "1px solid black",
                  "borderRight": border && border.borderRight ? border.borderRight : "1px solid black",
                  "borderDown": border && border.borderDown ? border.borderDown : "1px solid black",
                  "borderLeft": border && border.borderLeft ? border.borderLeft : "1px solid black"
       }}
         size={{ width: state.width, height: state.height}}
         onResizeStop={(e, direction, ref, d) => {
            if(type === "Column")
               localStorage["ColumnResized-"+column] = JSON.stringify({width: state.width + d.width , height: state.height });
            else
               localStorage["InvoiceResized"] = JSON.stringify({width: state.width + d.width , height: state.height  });

            setState({
               width: state.width + d.width, height: state.height});
            }}>
               {
               Children.map( children, child =>
                           <div>
                           {child}
                           </div>
                           )
               }
      </Resizable>
   );
}