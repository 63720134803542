import React, { useState } from "react";
import WorkflowStepDisplayCard from "./workflow_step_display_card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

/**
 * WorkFlowBranchEdit is a simple wrapper that passes all props down to WorkFlowBranch.
 */
const WorkFlowBranchEdit = ({
                                branch,
                                data,
                                onStepClicked,
                                addStep,
                                addBranch,
                                deleteStep,
                                deleteBranch,
                                branchPosition,
                                branch_index,
                                branchToDelete,
                            }) => {
    return (
        <WorkFlowBranch
            branch={branch}
            data={data}
            onStepClicked={onStepClicked}
            addStep={addStep}
            addBranch={addBranch}
            deleteStep={deleteStep}
            deleteBranch={deleteBranch}
            branchPosition={branchPosition}
            branch_index={branch_index}
            branchToDelete={branchToDelete}
        />
    );
};

/**
 * WorkFlowBranch renders a branch container along with its steps (or nested branches).
 * It handles hover effects, plus-button options, and drag/drop placeholders.
 */
const WorkFlowBranch = ({
                            branch,
                            data,
                            onStepClicked,
                            addStep,
                            addBranch,
                            deleteStep,
                            deleteBranch,
                            branchPosition,
                            branch_index,
                            branchToDelete,
                        }) => {
    // Local UI state for hover and plus-button display
    const [showPlusOptions, setShowPlusOptions] = useState(false);
    const [stepHovered, setStepHovered] = useState(null);
    const [branchHovered, setBranchHovered] = useState(false);

    // Helper functions to render branch border styles based on position
    const renderLeftBranchBorder = () => (
        <div className="d-flex w-100 justify-content-end border-2 position-relative">
            <div className="top-border w-50"></div>
        </div>
    );

    const renderMiddleBranchBorder = () => (
        <div className="d-flex w-100 justify-content-center border-2 position-relative">
            <div className="top-border w-100"></div>
        </div>
    );

    const renderRightBranchBorder = () => (
        <div className="d-flex w-100 justify-content-start border-2 position-relative">
            <div className="top-border w-50"></div>
        </div>
    );

    return (
        <div className="d-flex w-100 p-0 flex-column align-items-center" style={{ minWidth: "350px" }} onMouseLeave={() => setShowPlusOptions(false)}
        >
            {/* Render branch border based on branch position */}
            {branchPosition === "start"
                ? renderLeftBranchBorder()
                : branchPosition === "end"
                    ? renderRightBranchBorder()
                    : branchPosition === "middle"
                        ? renderMiddleBranchBorder()
                        : null}

            {/* Step connector area that shows “Remove Branch” on hover */}
            <div
                className={`step-connector ${branchHovered ? "expanded" : ""}`} style={{minWidth: "25%"}}
                onMouseEnter={() => setBranchHovered(true)}
                onMouseLeave={() => setBranchHovered(false)}
            >
                {branchHovered && (
                    <div className="d-flex my-3 justify-content-center line-in-middle">
                        <div className="card shadow-sm m-0 txt btn-light">
                            <div
                                className="p-2 justify-content-center align-items-center"
                                type="button"
                                onClick={() => deleteBranch(branchToDelete, branch_index)}
                            >
                                <span className="small no-wrap">{data.t.read("rule.remove_branch")}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Render each step within this branch */}
            {branch.steps?.map((step, index) => (
                <div key={index}>
                    {step.type === "branch" ? (
                        // If the step is a branch type, render it recursively.
                        <div className="d-flex border-radius-30 flex-column">
                            <div className="d-flex justify-content-center line-in-middle">
                                <div
                                    className="card shadow-sm mb-4 border-radius-30 btn-light"
                                    type="button"
                                    onClick={() => addBranch(branch, "parallel")}
                                >
                                    <div className="p-2 justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span className="ml-2">{data.t.read("branch")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex border-radius-30">
                                {step.branches.map((subBranch, idx) => (
                                    <div
                                        className="d-flex flex-column align-items-center"
                                        key={idx}
                                    >
                                        <WorkFlowBranchEdit
                                            branch={subBranch}
                                            data={data}
                                            onStepClicked={onStepClicked}
                                            addStep={addStep}
                                            addBranch={addBranch}
                                            deleteStep={deleteStep}
                                            deleteBranch={deleteBranch}
                                            branch_index={idx}
                                            branchToDelete={branch}
                                            branchPosition={
                                                step.branches.length === 1
                                                    ? "single"
                                                    : idx === 0
                                                        ? "start"
                                                        : idx === step.branches.length - 1
                                                            ? "end"
                                                            : "middle"
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        // Standard step rendering
                        <div className="d-flex flex-column mx-auto" style={{ width: "300px" }}>
                            <div className="d-flex flex-column align-items-center">
                                <WorkflowStepDisplayCard
                                    onClickFunc={() => onStepClicked(step)}
                                    data={data}
                                    step={step}
                                    branch={branch}
                                    isSelected={false}
                                    index={index}
                                    removeStep={() => deleteStep(branch, index)}
                                />
                                {/* Step connector for adding new steps */}
                                <div
                                    className={`w-25 step-connector ${
                                        stepHovered === index && index + 1 < branch.steps.length
                                            ? "expanded"
                                            : ""
                                    }`}
                                    onMouseEnter={() => setStepHovered(index)}
                                    onMouseLeave={() => setStepHovered(null)}
                                >
                                    {stepHovered === index && index + 1 < branch.steps.length && (
                                        <div className="p-1 btn-light border w-100 my-3">
                                            <div
                                                className="d-flex justify-content-start align-items-center"
                                                type="button"
                                                onClick={() => addStep(branch, stepHovered + 1)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                                <span className="ml-2">Step</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {/* Render add-new options if the last step isn’t a branch */}
            {branch.steps[branch.steps.length - 1]?.type !== "branch" && (
                <div onMouseLeave={() => setShowPlusOptions(false)}>
                    {showPlusOptions === false ? (
                        <div className="d-flex justify-content-center">
                            <div
                                className="card shadow-sm border-radius-30 p-0 mb-4 border btn-light p-3"
                                onClick={() => setShowPlusOptions(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex flex-column align-items-center w-100 mb-2 shadow-sm">
                            <div className="p-1 btn-light border w-100">
                                <div
                                    className="d-flex justify-content-start align-items-center"
                                    type="button"
                                    onClick={() => {
                                        setShowPlusOptions(false);
                                        addStep(branch, branch.steps.length);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <span className="ml-2">{data.t.read("step")}</span>
                                </div>
                            </div>
                            <div className="p-1 btn-light border border-top-0">
                                <div className="h-25">
                                    <div
                                        className="d-flex justify-content-start align-items-center"
                                        type="button"
                                        onClick={() => {
                                            setShowPlusOptions(false);
                                            addBranch(branch);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span className="ml-2">{data.t.read("branch")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default WorkFlowBranchEdit;