import CryptoJS from "crypto-js";

export default class Utils {
    static userHasPageAccess(data, page) {
        return data != null && data.config != null && data.config.components != null && data.config.components.findIndex(comp => comp.name === page) !== -1;
    }
    static userHasRole(data, roles) {
        return data != null  && roles.includes(data.role );
    }
    static decryptCardNumber(value) {
        return CryptoJS.AES.decrypt(value, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    }

    static encryptCardNumber(value) {  
        return CryptoJS.AES.encrypt(value, process.env.REACT_APP_SECRET_KEY).toString();
    }
    static formatCardNumberWithSpaces(number) {
        return number.replace(/(.{4})/g, "$1 ").trim();
    }

    static getMaskedDisplayValue(number) {
        if (number.length <= 4) return number; // Si ≤ 4 chiffres, ne pas masquer
        const visiblePart = number.slice(-4);
        const maskedPart = "•".repeat(number.length - 4);
        return this.formatCardNumberWithSpaces(maskedPart + visiblePart);
    };
}
