import iconv from 'iconv-lite';
import { useEffect, useState } from "react";
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { fetch } from '../utils/api-wrapper';

// currentInvoice refer to the dashboard
const InvoicePreview = ({ file_name, currentInvoice }) => {
    
    const source =  axios.CancelToken.source();

    const [pdf, setPdf] = useState("");
    const [hide, setHide] = useState(false);
    useEffect(() => {
        // currentInvoice === null means that no invoice selected, the modal is closed
        // we should try to load the pdf
        if(currentInvoice === null && pdf === ""){
            fetch("/invoice/pdf/" + file_name, "GET", {},
            response => {
                let buf = null;
                if (response.data.pdf != null) {
                    if(pdf === ""){
                        buf = iconv.encode(response.data.pdf, 'iso-8859-1');
                        let bb = new Blob([buf], { type: "application/pdf" });
                        buf = window.URL.createObjectURL(bb);
                        setPdf(buf);
                    }
                }
            },
            null, false, source.token);
        }
        
      }, [file_name])

    useEffect( () => () => source.cancel("preview invoice unmounted") , []);
    
    return pdf !== "" && pdf !== null ?
    <div>
        <div className="preview-plus" onClick={e => {setHide(!hide); e.stopPropagation();}}>
        </div>
            <object data={pdf} className="preview" alt="invoice" aria-label="invoice" />
        {
            hide && 
            <div className="fadeIn animate modal d-block" onClick={() => setHide(!hide)}>
                
                <div id='invoice-preview-modal' onClick={e => e.stopPropagation()} className="modal-dialog" role="document" style={{maxWidth : "50%", height: "70vh"}}>
                    <div className="modal-content" style={{width : "100%", height: "100%"}}>
                        <div className="modal-body">
                            <div id="icon-preview-close" className="pointer" onClick={() => setHide(false)}>
                                <div>
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </div>
                            </div>
                            <object width="100%" height="100%" type="application/pdf" data={pdf} aria-label="PDF"></object>
                        </div>
                    </div>
                </div>
            </div> 
        }
        </div>
    : 
    <div>
                <div className="card" id="card-link">
                    <div className="card__header">
                        <div>
                            <img className="card__header header__img skeleton" id="logo-img" alt="" />
                        </div>
                        <h3 className="card__header header__title" id="card-title">
                        <div className="skeleton skeleton-text"></div>
                        <div className="skeleton skeleton-text"></div>
                        </h3>
                    </div>

                    <div className="card__body">
                        <div className="card__body body__text" id="card-details">
                            <div className="skeleton skeleton-text skeleton-text__body"></div>
                        </div>

                        <div className="card__body body__img">
                            <img className="skeleton" alt="" id="cover-img" />
                        </div>
                    </div>

                    <div className="card__footer" id="card-footer">
                        <div className="skeleton skeleton-text skeleton-footer"></div>
                    </div>
                </div>
    </div>
}

export default InvoicePreview