import { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InvoicePreview from './Invoice-preview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Utils from "../utils/utils";
import Checkbox from "./checkbox";
import Checkmark from "./checkmark";
import Closemark from "./closemark";
import Modal from './modal';
import { fetch } from '../utils/api-wrapper';
import Select from 'react-select';
import { useEffect } from "react";
const TransactionList = ({ data, statement, saving, setCurrentTransaction, currentTransaction, fetchStatement, approveTransaction, disApproveTransaction, validateTransaction, updateTransaction }) => {
    const source = axios.CancelToken.source();
    const [transactionsDeleteSelection, setTransactionsDeleteSelection] = useState([]);
    const [transactionsDeleteConfirm, setTransactionsDeleteConfirm] = useState(false);
    const [matchingExpenses, setMatchingExpenses] = useState([]);

    const styleSelect = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "0 !important",
            boxShadow: state.isFocused ? "0px 0px 10px #F39800 " : "0 !important",
            padding: data.isMobile ? "0" : baseStyles.padding,
        })
    };

    useEffect(() => {
        setMatchingExpenses(statement?.transactions?.map(transaction => transaction.checksum));
    }, [statement]);

    const { statement_id } = useParams(); // Access the id parameter from the URL
    const isAllTransactionsSelected = () => {
        if (!statement || !statement.transactions)
            return false;
        
        return transactionsDeleteSelection.length > 1 && transactionsDeleteSelection.length === statement.transactions.filter(x => x.checksum).length;
    }
    const toggleAllTransactionsSelected = () => {
        transactionsDeleteSelection.length === statement.transactions.filter(x => x.checksum).length ? setTransactionsDeleteSelection([]) : setTransactionsDeleteSelection([...statement.transactions.filter(x => x.checksum).flatMap(x => x.checksum)]);
    }
    const isTransactionSelected = (transaction) => {
        return transactionsDeleteSelection.find(x => x === transaction.checksum) !== undefined;
    }
    const deleteTransactions = () => {
        fetch(`/c_card/transactions/${statement_id}`, "DELETE", { "checksums": transactionsDeleteSelection },
            response => {
                fetchStatement();
                setTransactionsDeleteSelection([]);
                setTransactionsDeleteConfirm(false);
            }
            , null, false, source.token);
    }
    

    return <><Accordion defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="xpenses-panel-content"
            id="xpenses-panel-header"
        >
            <h3>Xpenses</h3>
        </AccordionSummary>
        <AccordionDetails>
            {data.isMobile && <div className="text-right">
                <button hidden={statement?.status !== "Pending"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | transactionsDeleteSelection.length === 0} onClick={() => setTransactionsDeleteConfirm(true)}>
                    <div>
                        {data.t.read("reset")}
                        {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                    </div>
                </button>
                <Link hidden={statement?.status !== "Pending"} to={`/c_card/statement/transaction/add/${statement_id}?type=${statement.card_type}`} className="btn btn-sm btn-primary btn-primary-shadow pb-2 pt-2">
                    {data.t.read("add_new")}
                </Link>
            </div>}
            <div className="d-flex">
                <div className="col-8">
                    <table className="table table-borderless table-sm">
                        <thead>
                            <tr>
                                <th><Checkbox disabled={!statement || !statement.transactions || statement.transactions.filter(x => x.checksum).length === 0} isChecked={isAllTransactionsSelected()} onChange={toggleAllTransactionsSelected} /></th>
                                {!data.isMobile && 
                                <th className="text-center col-2"></th>}
                                <th className="text-center col-3">Xpense {data.t.read(`name`)}</th>
                                {!data.isMobile && 
                                <th className="text-center col-3">Xpense {data.t.read(`invoiceDate`)}</th>}
                                <th className="text-center col-3">{data.t.read(`invoice.total`)}</th>
                                {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) &&
                                !data.isMobile && <th className="text-center col-1">{data.t.read(`approval`)}</th> }
                                {!data.isMobile && <th className="text-center col-1">{data.t.read(`validate`)}</th> }
                            </tr>
                        </thead>
                        <tbody>
                            {statement && statement.transactions.map(
                                transaction => <tr key={transaction.number}>
                                    <td>
                                        <Checkbox disabled={!transaction.checksum} isChecked={isTransactionSelected(transaction)} onChange={e => isTransactionSelected(transaction) ? setTransactionsDeleteSelection(transactionsDeleteSelection.filter(x => x !== transaction.checksum)) : setTransactionsDeleteSelection([...transactionsDeleteSelection, transaction.checksum])} />
                                    </td>
                                    {!data.isMobile && 
                                    <td>
                                        {
                                            transaction.checksum ? 
                                                <InvoicePreview file_name={transaction.file_name ? transaction.file_name : `${statement_id}_${transaction.checksum}.pdf`} currentInvoice={currentTransaction} /> 
                                            : 
                                                <>
                                                {
                                                    statement.expenses && statement.expenses.length > 0 && statement.expenses.find(xp => ['Pending', 'Processing'].includes(xp.status)) ?
                                                    <span className="text-center">
                                                        <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                                                    </span>
                                                    :
                                                    <Select
                                                        name="xpense"
                                                        value={transaction.checksum && transaction.name  ? { value: transaction.checksum, label: transaction.name } : null}
                                                        options={statement.expenses && statement.expenses.filter(xp => matchingExpenses && !matchingExpenses.includes(xp.checksum)).map(xp => ({ label: xp.name, value: xp.checksum }))}
                                                        styles={styleSelect}
                                                        hidden={!statement.expenses || statement.expenses.length < 1 }
                                                        classNamePrefix="select"
                                                        onChange={choice => updateTransaction({...transaction,json: statement.expenses.find(xp => xp.checksum === choice.value).json, checksum: choice.value, name: choice.label})}
                                                    />}
                                                </>
                                        }
                                    </td>
                                    }
                                    {["Pending", "Processing"].includes(transaction.status) === false ?
                                        <td className="text-center" onClick={() => setCurrentTransaction(transaction)} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>
                                            {transaction.name}
                                        </td>
                                        :
                                        <td className="text-center">
                                            <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />
                                        </td>
                                    }
                                    {!data.isMobile && <td className="text-center">
                                        {transaction.date}
                                    </td>}
                                    <td className="text-center">
                                        {transaction.BigTotalPayed && `$${transaction.BigTotalPayed.toFixed(2)}`}
                                    </td>
                                    {Utils.userHasPageAccess(data, "approval") && Utils.userHasRole(data, ['Admin', 'Approver']) && !data.isMobile &&
                                        <td>
                                            <div className="d-flex text-center justify-content-center">
                                                <Checkmark circle={true} callback={() => fetchStatement()} disabled={statement.status !== "Need_Approval" || !Utils.userHasPageAccess(data, "approval") || !Utils.userHasRole(data, ['Admin', 'Approver'])} data={data} isChecked={transaction.status === 'Approved'} onChecked={() => approveTransaction(transaction)} />
                                                <Closemark callback={() => fetchStatement()} disabled={statement.status !== "Need_Approval" || !Utils.userHasPageAccess(data, "approval") || !Utils.userHasRole(data, ['Admin', 'Approver'])} data={data} isClosed={transaction.status === 'Denied'} onClosed={(causeDenial) => disApproveTransaction(transaction, causeDenial)} />
                                            </div>
                                        </td>
                                    }
                                    {!data.isMobile && <td className="text-center">
                                        <Checkmark callback={() => fetchStatement()} disabled={statement.status === 'Pending'} isChecked={transaction.status === 'Validated'} onChecked={() => validateTransaction(transaction)} />
                                    </td>}

                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {!data.isMobile && <div className="col-4 text-right">
                    <button hidden={statement?.status !== "Pending"} type="button" className="mr-1 pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving | transactionsDeleteSelection.length === 0} onClick={() => setTransactionsDeleteConfirm(true)}>
                        <div>
                            {data.t.read("reset")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </div>
                    </button>
                    <Link hidden={statement?.status !== "Pending"} to={`/c_card/statement/${statement_id}/add?type=${statement?.card_type}`} className="btn btn-sm btn-primary btn-primary-shadow pb-2 pt-2">
                        {data.t.read("add_new")}
                    </Link>
                </div>}
            </div>
        </AccordionDetails>
    </Accordion>
    {
        transactionsDeleteConfirm === true &&
        <Modal title={data.t.read("confirm")} cancelAction={() => setTransactionsDeleteConfirm(false)} okAction={() => deleteTransactions()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
            {data.t.read("delete")} ?
        </Modal>

    }
    </>
}

export default inject('data')(observer(TransactionList));